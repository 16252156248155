import React, {useEffect, useState} from "react"

const Installer = ({children}) => {
    const [installEvent, setInstallEvent] = useState()

    useEffect(() => {
        window.addEventListener('beforeinstallprompt',
            (event) => {
                event.preventDefault()
                setInstallEvent(event)
            }
        )
    }, [])
/*
    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase()
        return /iphone|ipad|ipod/.test( userAgent )
      }
      // Detects if device is in standalone mode
      const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone)
      
      // Checks if should display install popup notification:
      if(isIos() && !isInStandaloneMode()) {
         setInstallEvent(true)
      }
*/

    return <>
        {installEvent && (
            <button className="app-installer"
                onClick={async () => {
                    installEvent.prompt()
                    await installEvent.userChoice
                    setInstallEvent(null)
                }}
            >Установить</button>
        )}
        {children}
    </>
}

export default Installer