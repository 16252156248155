import React, {useState, useEffect} from 'react'
import { useQuery, useApolloClient, gql } from '@apollo/client'
import {Navigate, Link} from 'react-router-dom'
import { easings } from 'react-animation'
import PropagateLoader from 'react-spinners/PropagateLoader'
import Media from 'react-media'

import FullStatus from '../comps/FullStatus.js'
import FullButton from '../comps/FullButton.js'
import AddressButton from '../comps/AddressButton.js'

function StoreFullsReady({visible, focus, action, data}){
    const [selectedRow, setSelectedRow] = useState(focus),
          [isActive, setActive] = useState(false)

    useEffect(() => {
        if(focus != 0) setSelectedRow(focus)
    }, [focus])

    
    useEffect(() => {
        visible ? setActive(true) : setTimeout(() => setActive(false), 1000)
    }, [visible])

    if(isActive)
    return ( 
        <div id="full" style={{ animation: `slide-in ${easings.easeOutExpo} 900ms forwards`}}>
            <div className="dtable">
              <div className="drow rhead">
                <div className="dhead">создан</div>
                <div className="dhead">клиент</div>
                <div className="dhead">номер</div>
                <div className="dhead">название</div>
                <div className="dhead">доставка</div>
                <div className="dhead">пункт</div>
                <div className="dhead">цена</div>
                <div className="dhead dlast">статус</div>
              </div>

            {data.map(full => (
            <div key={full.id} className={selectedRow == full.track ? "drow drow-active" : "drow"} onClick={() => action(full.track)}>
              <div className="dcell">{String((new Date(full.created)).toLocaleTimeString()).slice(0,-3)} <span>{(new Date(full.created)).toLocaleDateString()}</span></div>
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell"><span>Клиент:</span> {full.client}</div>
              ) : (
                <div className="dcell">{full.client}</div>
              )}
              </Media> 
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell"><span>№</span> {full.track.length > 10 ? full.track.slice(0,10) + ".." : full.track}</div>
              ) : (
                <div className="dcell">{full.track.length > 10 ? full.track.slice(0,10) + ".." : full.track}</div>
              )}
              </Media> 
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell"><span>Груз:</span> {full.count} шт. {full.title.length > 30 ? full.title.slice(0,30) + ".." : full.title}</div>
              ) : (
                <div className="dcell">{full.count} шт. {full.title.length > 40 ? full.title.slice(0,40) + ".." : full.title}</div>
              )}
              </Media> 
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell"><span>Доставка:</span> {full.address.length > 30 ? full.address.slice(0,30) + ".." : full.address}</div>
              ) : (
                <div className="dcell">{full.address.length > 30 ? full.address.slice(0,30) + ".." : full.address}</div>
              )}
              </Media> 
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell"><span>Доставка:</span> {full.dest == '1' && 'Гуанчжоу  '}{full.dest == '2' && 'Алматы  '}{full.dest == '3' && 'Москва  '}  {full.address.length > 30 ? full.address.slice(0,30) + ".." : full.address}</div>	
              ) : (
                <div className="dcell">{full.dest == '1' && 'Гуанчжоу'}{full.dest == '2' && 'Алматы'}{full.dest == '3' && 'Москва'}</div>	
              )}
              </Media>    
              <div className="dcell">{full.customer.resident == 1 ? new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'}).format(full.total) : new Intl.NumberFormat('kz-KZ', {style: 'currency', currency: 'KZT'}).format(full.total)}</div>
              <div className="dcell dlast align-center status"><FullStatus status={full.status} /></div>
            </div> 
            ))}  
        </div>
      </div>
    )
}
export default StoreFullsReady
