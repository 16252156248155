import React, {useRef, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import { useMutation, useQuery, useApolloClient, gql } from '@apollo/client'
import { easings } from 'react-animation'
import PropagateLoader from 'react-spinners/PropagateLoader'
import Media from 'react-media'

import OrderPriceCargo from './OrderPriceCargo.js'
import OrderPriceCategory from './OrderPriceCategory.js'
import OrderPriceCargoWhite from './OrderPriceCargoWhite.js'

import {ReactComponent as SaveSvg} from '../lib/svg/save.svg'
import {ReactComponent as CloseSvg} from '../lib/svg/close.svg'
import {ReactComponent as CheckSvg} from '../lib/svg/check.svg'
import {ReactComponent as Arrows2Svg} from '../lib/svg/arrows2.svg'

function Store({user, orderTrack, action, rateExport}){
    const apollo = useApolloClient(),
          [isEdit, setEdit] = useState(false),
          [isDelError, setDelError] = useState(false),
          [delConfirm, setDelConfirm] = useState(false),
          [creator, setCreator] = useState(''),
          [editor, setEditor] = useState(''),
          [created, setCreated] = useState(),
          [updated, setUpdated] = useState(),
          [id, setId] = useState(''),
          [status, setStatus] = useState(''),
          [desc, setDesc] = useState(''),
          [title, setTitle] = useState(''),
          [comment, setComment] = useState(null),
          [track, setTrack] = useState(),
          [trackReady, setTrackReady] = useState(false),
          [trackCheck, setTrackCheck] = useState(false),
          [client, setClient] = useState(''),
          [price, setPrice] = useState(null),
          [nds, setNds] = useState(null),
          [weight, setWeight] = useState(null),
          [size, setSize] = useState(null),
          [density, setDensity] = useState(),
          [count, setCount] = useState(null),
          [dest, setDest] = useState(''),
          [tarif, setTarif] = useState(1),
          [tarifs, setTarifs] = useState({std: 0, exp: 0, odn: 0}),
          [type, setType] = useState(''),
          [currency, setCurrency] = useState(''),
          [rateCurrency, setRateCurrency] = useState(''),
          [format, setFormat] = useState(''),
          [ndsTax, setNdsTax] = useState(null),
          [toll, setToll] = useState(null),
          [cost, setCost] = useState(null),
          [broker, setBroker] = useState(null),
          [trans, setTrans] = useState(null),
          [carrier, setCarrier] = useState(''),
          [carcont, setCarcont] = useState(''),
          [total, setTotal] = useState(null),
          [range, setRange] = useState(null),
          [category, setCategory] = useState(0),
          [categoryTitle, setCategoryTitle] = useState('')
       
    const onChange = event => {
        if(event.target.name == 'size'){
          setSize(event.target.valueAsNumber)
          setDensity(parseInt(weight / event.target.valueAsNumber))
        }
        else if(event.target.name == 'weight'){
          setWeight(event.target.valueAsNumber)
          setDensity(parseInt(event.target.valueAsNumber / size))
        }
        else if(event.target.name == 'price') setPrice(event.target.valueAsNumber)
        else if(event.target.name == 'nds')   setNds(event.target.valueAsNumber)
        else if(event.target.name == 'count') setCount(event.target.valueAsNumber)
        else if(event.target.name == 'dest')  setDest(event.target.value)
        else if(event.target.name == 'type')  setType(event.target.value)
        else if(event.target.name == 'desc')  setDesc(event.target.value)
        else if(event.target.name == 'title') setTitle(event.target.value)
        else if(event.target.name == 'comment')setComment(event.target.value)
        else if(event.target.name == 'track') setTrack(event.target.value)
        else if(event.target.name == 'client')setClient(event.target.value)
        else if(event.target.name == 'toll')  setToll(event.target.valueAsNumber)
        else if(event.target.name == 'cost')  setCost(event.target.valueAsNumber)
        else if(event.target.name == 'broker')setBroker(event.target.valueAsNumber)
        else if(event.target.name == 'trans') setTrans(event.target.valueAsNumber)
        else if(event.target.name == 'carrier')setCarrier(event.target.value)
        else if(event.target.name == 'carcont')setCarcont(event.target.value)
        else if(event.target.name == 'status')setStatus(event.target.value)
        else if(event.target.name == 'tarif') {
          let value = event.target.value
          setTarif(value)
          if(value == 1) setTrans(parseFloat(tarifs.std * weight).toFixed(2))
          if(value == 2) setTrans(parseFloat(tarifs.exp * weight).toFixed(2))
          if(value == 3) setTrans(parseFloat(tarifs.odn * weight).toFixed(2))    
        }  
        setEdit(false)
    }

    const EDIT_ORDER = gql`
    mutation editOrder($id: ID, $client: Int, $category: Int, $title: String, $comment: String, $desc: String, $track: String, $price: Float, $nds: Float, $weight: Float, $size: Float, $count: Int, $dest: String, $tarif: Int, $type: String, $cost: Float, $toll: Float, $trans: Float, $broker: Float, $carrier: String, $carcont: String, $status: Int, $edited: ID) {
      editOrder(id: $id, client: $client, category: $category, title: $title, comment: $comment, desc: $desc, track: $track, price: $price, nds: $nds, weight: $weight, size: $size, count: $count, dest: $dest, tarif: $tarif, type: $type, cost: $cost, toll: $toll, trans: $trans, broker: $broker, carrier: $carrier, carcont: $carcont, status: $status, edited: $edited)}` 

    const DEL_ORDER = gql`mutation deleOrder($id: ID!, $token: String) {deleOrder(id: $id, token: $token)}`;
  
    const letOrder = (e) => {
      e.preventDefault()
      editOrder({variables: {id, client: Number(client), category: Number(category), title, comment, desc, track, price: Number(price), nds: Number(nds), weight: Number(weight), size: Number(size), count: Number(count), dest, tarif: Number(tarif), type, cost: Number(cost), toll: Number(toll), trans: Number(trans), broker: Number(broker), carrier, carcont, status: Number(status), edited: user.id}})
    } 

    const delOrder = () => {
          setDelError(false)
          deleOrder({variables: { id, token: user.token}})
    } 

    const [editOrder, { error }] = useMutation(EDIT_ORDER, {
        onCompleted: data => {
            apollo.refetchQueries({include: [GET_ORDER]})
            setEdit(true)
        }
    }) 
    const [deleOrder] = useMutation(DEL_ORDER, {
      onCompleted: data => {
          data?.deleOrder ? action() : setDelError(true)
      }
    })


    const GET_ORDER = gql`
    query getOrder($track: String) {
      order(track: $track){
        id
        client
        category
        dest
        tarif
        type
        title
        comment
        desc
        track
        weight
        size
        count
        status
        status_1
        status_2
        status_3
        status_4
        status_5
        price
        cost
        toll
        nds
        broker
        trans
        carrier
        carcont
        created
        updated
        customer{
          fio
          client
          inn
          resident
          mobile
          wtsapp
          email
          created
        }
        postedBy{
          fio
          group
          mobile
          wtsapp
          email
          created
        }
      }
    }`

    const {data, loading} = useQuery(GET_ORDER, {variables: {track: orderTrack}})

    const GET_CLIENTS = gql`query getClients {clients{fio client}}`
    const {data: clientList} = useQuery(GET_CLIENTS)

    useEffect(() => {
      if(data?.order){
        setId(data.order.id)
        setCreator(data.order.customer?.id)
        setEditor(data.order.postedBy.id)
        setCreated(data.order.created)
        setUpdated(data.order.updated)  
        setStatus(data.order.status)
        setDesc(data.order.desc)
        setTitle(data.order.title)
        setComment(data.order.comment)
        setTrack(data.order.track)
        setClient(data.order.client)
        setCategory(data.order.category)
        setPrice(data.order.price)
        setNds(data.order.nds)
        setWeight(data.order.weight)
        setSize(data.order.size)
        setCount(data.order.count)
        setDest(data.order.dest)
        setTarif(data.order.tarif)
        setType(data.order.type)
        setToll(data.order.toll)
        setCost(data.order.cost)
        setBroker(data.order.broker)
        setTrans(data.order.trans)
        setCarrier(data.order.carrier)
        setCarcont(data.order.carcont)
        setDensity(Math.ceil(data.order.weight / data.order.size))
        
        if(data.order.customer){
          setFormat(data.order.customer.resident == 1 ? 'ru-RU' : 'kz-KZ')
          setCurrency(data.order.customer.resident == 1 ? 'RUB' : 'KZT')
          setRateCurrency(data.order.customer.resident == 1 ? rateExport[1] : rateExport[0])
          setNdsTax(data.order.customer.resident == 1 ? .2 : .12)
        }
        else{
          setFormat('')
          setCurrency('')
          setNdsTax('')   
        }
        let range = 0
        if(data.order.status_1) range = 20
        if(data.order.status_2) range = 40
        if(data.order.status_3) range = 60
        if(data.order.status_4) range = 80
        if(data.order.status_5) range = 100
        setRange(range)
      }
    }, [data])

    useEffect(() => {
        setTotal(Number(toll) + Number(cost) + Number(broker) + Number(trans))
    }, [toll, cost, broker, trans])
    

    if(loading) return <p className="request-result"><PropagateLoader color="#392f64" /></p>
    if(error) return <p className="request-result">Ошибка: {error}</p>
    if(data?.order)
    return (
      <>
        <div id="form-order" style={{ animation: `pop-in ${easings.easeInOutQuint} 800ms forwards`}}>
          <div className="mod-comp-wrap">
            <div className="head">
              <h3>Редактировать груз<span className="order-delete" onClick={() => setDelConfirm(v => !v)}><CloseSvg /> {isDelError ? 'ошибка удаления' : 'удалить заказ'} <b className={delConfirm && 'active'} onClick={delOrder}>подтверждаю удаление</b></span></h3>
            </div>
            <div className="order-range">
            <div className="flex">
              <div className={data.order.status_1 != null && "ready"}>Прибыл в Гуанчжоу<br /><span>{data.order.status_1 != null ? <>{String((new Date(data.order.status_1)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(data.order.status_1)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={data.order.status_3 != null && "ready"}><Arrows2Svg /></div>
              <div className={data.order.status_3 != null && "ready"}>Отправлен в Алматы<br /><span>{data.order.status_3 != null ? <>{String((new Date(data.order.status_3)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(data.order.status_3)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={data.order.status_4 != null && "ready"}><Arrows2Svg /></div>
              <div className={data.order.status_4 != null && "ready"}>Отправлен в Москву<br /><span>{data.order.status_4 != null ? <>{String((new Date(data.order.status_4)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(data.order.status_3)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={data.order.status_5 != null && "ready"}><Arrows2Svg /></div>
              <div className={data.order.status_5 != null && "ready"}>Выдан клиенту<br /><span>{data.order.status_5 != null ? <>{String((new Date(data.order.status_5)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(data.order.status_5)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
            </div>
            <meter value={range} max="100" low="40" high="70" optimum="90" />
            </div>
            <form method="post" onSubmit={letOrder}>
            <div className="store-flex">
              <ul>
                <h3>Получатель</h3>
                <li><span>Дата регистрации</span><i></i><b>{(new Date(data?.order?.customer?.created || new Date())).toLocaleString()}</b></li> 
                <li><span>ФИО</span><i></i><b className="client">{data.order.customer && data.order.customer.fio}</b></li>
                <li><span>Резидент</span><i></i><b>{data.order.customer && (data.order.customer.resident == 1 ? "Россия" : "Казахстан")} {data.order.customer.client} </b></li>
                <li><span>ИНН</span><i></i><b>{data?.order?.customer?.inn}</b></li>
                <li><span>Тел.мобильный</span><i></i><b><a href={`tel:${data.order.customer && data.order.customer.mobile}`}>{data.order.customer && data.order.customer.mobile}</a></b></li>
                <li><span>What`s App</span><i></i><b><a href={`https://api.whatsapp.com/send?phone=${data.order.customer && data.order.customer.wtsapp.replace(/\+/g, '').replace(/\(/g, '').replace(/\)/g, '').replaceAll(/\-/g, '')}`}>{data.order.customer && data.order.customer.wtsapp}</a></b></li>
                <li><span>E-mail</span><i></i><b>{data.order.customer && data.order.customer.email}</b></li>
               </ul>
              <ul>
                <h3>Оформил</h3>
                <li><span>Дата оформления</span><i></i><b>{(new Date(data.order.created)).toLocaleString()}</b></li> 
                <li><span>ФИО</span><i></i><b className="client">{data?.order?.postedBy?.fio}</b></li>
                <li><span>Дата регистрации</span><i></i><b>{(new Date(data?.order?.postedBy?.created)).toLocaleString()}</b></li> 
                <li><span>Группа</span><i></i><b>{data?.order?.postedBy?.group == 5 ? "клиент" : "сотрудник"}</b></li>
                <li><span>Тел.мобильный</span><i></i><b><a href={`tel:${data?.order?.postedBy?.mobile}`}>{data?.order?.postedBy?.mobile}</a></b></li>
                <li><span>What`s App</span><i></i><b><a href={`https://api.whatsapp.com/send?phone=${data?.order?.postedBy?.wtsapp.replace(/\+/g, '').replace(/\(/g, '').replace(/\)/g, '').replaceAll(/\-/g, '')}`}>{data?.order?.postedBy?.wtsapp}</a></b></li>
                <li><span>E-mail</span><i></i><b>{data?.order?.postedBy?.email}</b></li>
              </ul>
              <ul>
                <h3>Доставка</h3> 
                
                <li><span>Дата обновления</span><i></i><b>{data.order.updated ? (new Date(data.order.updated)).toLocaleString() : 'не обновлялся'}</b></li> 
                <li><span>Пункт назначения</span><i></i><b>{data.order.dest}</b></li> 
                <li><span>Вид доставки</span><i></i><b>{data.order.type}</b></li>
                <fieldset className="field-wrap mt-2">
                  <select id="status" name="status" value={status} onChange={onChange} required>
                    <option value="0">Не прибыл на склад</option>
                    <option value="1">Прибыл в Гуанчжоу</option>
                    <option value="3">Отправлен в Алматы</option>
                    <option value="4">Отправлен в Москву</option>
                    <option value="5">Выдан клиенту</option>
                  </select>
                  <label htmlFor="carrier">Статус доставки</label>
                </fieldset>
                <Media queries={{small: '(max-width:800px)'}}>
                {(size) => size.small && (
                  <fieldset className={track != "" ? "field-wrap ready" : "field-wrap"}>
                    <input type="text" id="track" name="track" value={track} onChange={onChange} autoComplete='off' required />
                    <label htmlFor="track">Трэк номер <CheckSvg /></label>
                  </fieldset>
                )}
              </Media> 
                <input type="hidden" name="id" value={id} required />
              </ul>
            </div>

            <OrderPriceCategory editable={true} trackReady={trackReady} trackCheck={true} category={category} setCategory={setCategory} setCategoryTitle={setCategoryTitle} />

              <fieldset className={dest != '' ? "radio-wrap short grn" : "radio-wrap short"}>
                <legend>Пункт назначения</legend>
                <div className={dest == 'Москва' && 'active'}><label htmlFor="dest-1">Москва</label> <span><input type="radio" name="dest" id="dest-1" value="Москва" checked={dest == 'Москва'} onChange={onChange} required /></span></div>
                <div className={dest == 'Алматы' && 'active'}><label htmlFor="dest-2">Алматы</label> <span><input type="radio" name="dest" id="dest-2" value="Алматы" checked={dest == 'Алматы'} onChange={onChange} required /></span></div>
            </fieldset>
              <fieldset className={type != '' ? "radio-wrap long grn" : "radio-wrap long"}>
                <legend>Вид доставки</legend>
                <div className={type == 'BQQ растаможка' ? "w-30 active" : "w-30"}><label htmlFor="type-1">BQQ растаможка КАРГО</label> <span><input type="radio" name="type" id="type-1" value="BQQ растаможка" checked={type == 'BQQ растаможка'} onChange={onChange} required /></span></div>
                <div className={type == 'Собственная растаможка' ? "w-70 active" : "w-70"}><label htmlFor="type-2">Собственная растаможка БЕЛАЯ ЛОГИСТИКА</label> <span><input type="radio" name="type" id="type-2" value="Собственная растаможка" checked={type == 'Собственная растаможка'} onChange={onChange} required /></span></div>
              </fieldset>
              <fieldset className="fieldset-wrap">
                <fieldset className={title == "" ? "field-wrap mb-2" : "field-wrap mb-2 ready"}>
                  <input type="text" id="title" name="title" value={title} onChange={onChange} autoComplete='off' required />
                  <label htmlFor="title">Краткое наименование груза <CheckSvg /></label>
                </fieldset>
                <fieldset className={desc == "" ? "field-wrap long-wrap" : "field-wrap long-wrap ready"}>
                  <textarea className="store" id="desc" name="desc" value={desc} onChange={onChange} required />
                  <label htmlFor="desc">Комментарий и описание груза <CheckSvg /></label>
                </fieldset>
                <fieldset className={carrier == null ? "field-wrap" : "field-wrap ready"}>
                  <input type="text" id="carrier" name="carrier" value={carrier} onChange={onChange} autoComplete='off' />
                  <label htmlFor="carrier">Перевозчик <CheckSvg /></label>
                </fieldset>
                <fieldset className={carcont == null ? "field-wrap" : "field-wrap ready"}>
                  <input type="text" id="carcont" name="carcont" value={carcont} onChange={onChange} autoComplete='off' />
                  <label htmlFor="carcont">Контакт перевозчика <CheckSvg /></label>
                </fieldset>
                <fieldset className={comment ? "field-wrap ready" : "field-wrap"}>
                  <input type="text" id="comment" name="comment" value={comment} onChange={onChange} autoComplete='off' />
                  <label htmlFor="comment">Служебный комментарий <CheckSvg /></label>
                </fieldset>
              </fieldset> 

              <fieldset className="fieldset-wrap">
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => !size.small && (
                <fieldset className={track != "" ? "field-wrap ready" : "field-wrap"}>
                  <input type="text" id="track" name="track" value={track} onChange={onChange} autoComplete='off' required />
                  <label htmlFor="track">Трэк номер <CheckSvg /></label>
                </fieldset>
              )}
              </Media> 
                <div className="flex">
                  <fieldset className={client == "" ? "field-wrap sm" : "field-wrap sm ready"}>
                    <input className="client" list="clients" type="text" id="client" name="client" value={client} onChange={onChange} autoComplete='off' required />
                    <label htmlFor="client">Клиент <CheckSvg /></label>
                    <datalist id="clients">{clientList && clientList.clients.map(client => <option value={client.client} label={client.fio} />)}</datalist>
                  </fieldset>
                  <fieldset className={nds == 0 || !nds ? "field-wrap sm" : "field-wrap sm ready"}>
                    <input type="number" id="nds" name="nds" value={Number.parseInt(nds)} onChange={onChange} autoComplete="off" />
                    <label htmlFor="price">НДС {currency}<CheckSvg /></label>
                  </fieldset>
                </div>
                <div className="flex reversed">
                <fieldset className={count == 0 || !count ? "field-wrap sm" : "field-wrap sm ready"}>
                    <input type="number" id="count" name="count" value={count} onChange={onChange} autoComplete="off" />
                    <label htmlFor="count">Количество мест <CheckSvg /></label> 
                </fieldset>
                <fieldset className={price == 0 || !price ? "field-wrap sm" : "field-wrap sm ready"}>
                    <input type="number" id="price" name="price" value={price} onChange={onChange} autoComplete="off" />
                    <label htmlFor="price">Цена груза в {currency}<CheckSvg /></label>
                </fieldset>
                </div>
                <div className="flex">
                <fieldset className={size == 0 || !size ? "field-wrap sm" : "field-wrap sm ready"}>
                    <input type="number" id="size" name="size" step="0.001" value={size} onChange={onChange} autoComplete="off" />
                    <label htmlFor="size">Объем, м3 <CheckSvg /></label>
                </fieldset>
                <fieldset className={weight == 0 || !weight ? "field-wrap sm" : "field-wrap sm ready"}>
                    <input type="number" id="weight" name="weight" step="0.001" value={weight} onChange={onChange} autoComplete="off" />
                    <label htmlFor="weight">Вес, кг <CheckSvg /></label>
                </fieldset>
                </div>

                <div className="flex">
                <fieldset className={trans == 0 || !trans ? "field-wrap sm" : "field-wrap sm ready"}>
                    <input type="number" id="trans" name="trans" value={trans} onChange={onChange} autoComplete="off" />
                    <label htmlFor="trans">Цена доставки USD <CheckSvg /></label>
                </fieldset>
                <fieldset className={cost == 0 || !cost ? "field-wrap sm" : "field-wrap sm ready"}>
                    <input type="number" id="cost" name="cost" value={cost} onChange={onChange} autoComplete="off" />
                    <label htmlFor="cost">Цена себестоимости USD <CheckSvg /></label>
                </fieldset>
                </div>
                <div className="flex">
                <fieldset className={toll == 0 || !toll ? "field-wrap sm" : "field-wrap sm ready"}>
                    <input type="number" id="toll" name="toll" value={toll} onChange={onChange} autoComplete="off" />
                    <label htmlFor="toll">Таможенные пошлины USD <CheckSvg /></label>
                </fieldset>
                <fieldset className={broker == 0 || !broker ? "field-wrap sm" : "field-wrap sm ready"}>
                    <input type="number" id="broker" name="broker" value={broker} onChange={onChange} autoComplete="off" />
                    <label htmlFor="broker">Услуги брокера USD <CheckSvg /></label>
                </fieldset>
                </div>
                {type == 'BQQ растаможка' &&
                <fieldset className={dest != '' ? "radio-wrap tarif grn" : "radio-wrap tarif"}>
                  <legend>Доступные маршруты</legend>
                  <div className={tarifs.std ? tarif == '1' && 'active' : 'disabled'}><label htmlFor="tarif-1">Стандарт</label> <span><input type="radio" name="tarif" id="tarif-1" value="1" checked={tarif == '1'} onChange={onChange} disabled={!tarifs.std} /></span></div>
                  <div className={tarifs.exp ? tarif == '2' && 'active' : 'disabled'}><label htmlFor="tarif-2">Экспресс</label> <span><input type="radio" name="tarif" id="tarif-2" value="2" checked={tarif == '2'} onChange={onChange} disabled={!tarifs.exp} /></span></div>
                  <div className={tarifs.odn ? tarif == '3' && 'active' : 'disabled'}><label htmlFor="tarif-3">Однотип</label> <span><input type="radio" name="tarif" id="tarif-3" value="3" checked={tarif == '3'} onChange={onChange} disabled={!tarifs.odn} /></span></div>
                </fieldset>
                }
                <div className="flex flex-end">
                    <div><p className="total"><b>{format != '' && total > 0 && new Intl.NumberFormat(format, {style: 'currency', currency}).format(total * rateCurrency)}</b></p></div>
                    <button className="grn" type="submit">{isEdit ? <>Заказ обновлен</> : <>Сохранить <SaveSvg /></>}</button>
                </div>
              </fieldset>  
            </form>
          </div> 
      </div>
      {type == 'BQQ растаможка' && size > 0.001 && weight > 0.001 && currency && track && client && <OrderPriceCargo store={true} rateCurrency={rateCurrency} categoryTitle={categoryTitle} category={category} currency={currency} weight={weight} size={size} count={count} density={density} setTarifs={setTarifs} setTrans={setTrans} />}
      {type == 'Собственная растаможка' && currency && track && client && <OrderPriceCargoWhite rateCurrency={rateCurrency} currency={currency} categoryTitle={categoryTitle} category={category} weight={weight} size={size} count={count} type={type} price={price} />}
      </>
    )
}
export default Store
