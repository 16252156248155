import React, {useState, useEffect} from 'react'
import { useMutation, useQuery, gql } from '@apollo/client'
import {Navigate} from 'react-router-dom'
import InputMask from 'react-input-mask'
import { easings } from 'react-animation'
import {format} from 'date-fns'
import PropagateLoader from 'react-spinners/PropagateLoader'

import Confirm from '../comps/Confirm.js'
import FullsReady from '../comps/FullsReady.js'
import StoreFullsReady from '../comps/StoreFullsReady.js'
import DeliveryReady from '../comps/DeliveryReady.js'
import StoresReady from '../comps/StoresReady.js'

import {ReactComponent as CheckSvg} from '../lib/svg/check.svg'
import {ReactComponent as RegisterSvg} from '../lib/svg/register.svg'
import {ReactComponent as LoginSvg} from '../lib/svg/login.svg'
import {ReactComponent as EditSvg} from '../lib/svg/edit.svg'
import {ReactComponent as CloseSvg} from '../lib/svg/close.svg'

const CHECK_URL = process.env.REACT_APP_CHECK_URL

function PhoneInput(props) {
  return <InputMask mask='+9(999)999-99-99' id={props.id} type={props.type} name={props.name} value={props.value} disabled={props.disabled} onChange={props.onChange} onBlur={props.onBlur} />
}

function Profile({user, focusFull, focusOrder, action, showOrder, showFull, logout}){
    useEffect(() => {document.title = 'Профиль :: BQQ Trade House'})
    const token= user.token,
          id = user.id,
          [fio, setFio] = useState(""),
          [resident, setResident] = useState(0),
          [password, setPassword] = useState(""),
          [wtsapp, setWtsapp] = useState(""),
          [email, setEmail] = useState(""),
          [confirm, setConfirm] = useState(false),
          [isConfirmMobile, setConfirmMobile] = useState(false),
          [inn, setInn] = useState(""),
          [isRedacted, setRedacted] = useState(false),
          [isError, setError] = useState(false),
          [fioError, setFioError] = useState(""),
          [passwordError, setPasswordError] = useState(""),
          [wtsappError, setWtsappError] = useState(""),
          [emailError, setEmailError] = useState(""),
          [innError, setInnError] = useState(""),
          [ordersReady, setOrdersReady] = useState([]),
          [fullsReady, setFullsReady] = useState([]),
          [isOrdersReady, showOrdersReady] = useState(false),
          [isFullsReady, showFullsReady] = useState(false)

    function capitalizeWords(string) {
        return string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase() })
    }
    const onChange = event => {
      if(event.target.name == 'fio'){
        let rex = /[A-Za-z0-9]*$/
        let fio = event.target.value.replace(rex, '')
        let words = fio.split(" ")
        fio = capitalizeWords(fio.toLowerCase())
        if(words.length < 2 || words[0] == '' || words[1] == ''){
              setFioError('от 2 до 3 слов, рус.буквы')
              setFio(fio)
        }
        else if(words.length > 3){
              setFioError('')
        }
        else{
              setFioError('')
              setFio(fio)
        }
      }
      else if(event.target.name == 'password'){
        let password = event.target.value
        if(password.length < 7){
          setPasswordError('Пароль от 8 до 12 символов')
        }
        else{
          setPasswordError('')
        }
        if(password.length < 13) setPassword(event.target.value)
      }
      else if(event.target.name == 'wtsapp'){
        let wtsapp = event.target.value
        if(wtsapp.includes("_")){
          setWtsappError("укажите номер полностью")
          setWtsapp(wtsapp)
        }
        else{
          setWtsappError(false)
          setWtsapp(wtsapp)
        }  
      }
      else if(event.target.name == 'email'){
        setEmailError('')
        setEmail(event.target.value)
      }
      else if(event.target.name == 'inn'){
        let inn = event.target.value
        if(inn.length < 13){
          setInn(inn)
          setInnError('поле состоит из 12 символов')
        }
        else{
          setInnError('')
        }
      }
      else if(event.target.name == 'resident') setResident(event.target.value)

      if(fioError || passwordError || innError) setError(true)
      else setError(false)  
    }

    async function checkParam(event){
      let name  = event.target.name,
          value = event.target.value,
          result, data
      if(value.length < 1) return
      result = await fetch(`${CHECK_URL}/${name}/${value}`)
      data   = await result.json()
      if(data.check == 1 && data.id != user.id){
        setError(true)
        if(data.param == 'wtsapp') setWtsappError('этот номер уже зарегистрирован') 
        if(data.param == 'email')  setEmailError('этот e-mail уже зарегистрирован')   
        if(data.param == 'inn')    setInnError('этот ИНН уже зарегистрирован')     
      }
      else setError(false)
    }   


    const EDIT_USER = gql`
    mutation editUser($id: ID, $fio: String, $wtsapp: String, $email: String, $password: String, $inn: String, $resident: Int) {
      editUser(id: $id, fio: $fio, wtsapp: $wtsapp, email: $email, password: $password, inn: $inn, resident: $resident)}` 

    const letEdit = (e) => {
        e.preventDefault()
        editUser({variables: {id, fio, wtsapp, email, password, inn, resident: Number(resident)}})
    } 

    const [editUser, { }] = useMutation(EDIT_USER, {
        onCompleted: data => {
          const user = data.editUser.split("|")
          action(user[0], user[1], user[2], user[3], user[4], user[5], user[6])
          setRedacted(true)
        }
    }) 

    const GET_USER = gql`
    query User($token: String!) {
      user(token: $token) {
        id
        fio
        inn
        group
        resident
        mobile
        confirm
        email
        wtsapp
        address
        created
        lastdate
        ordersReady{
          id
          dest
          type
          title
          client
          track
          weight
          size
          count
          status
          price
          created
        }
        fullsReady{
          id
          dest
          title
          client
          image
          track
          address
          count
          status
          total
          created
          customer{resident}
        }        
      }
    }`
    const confirmed = () => {
      setConfirmMobile(false)
      setConfirm(true)
    }

    const {loading, data: profile, error} = useQuery(GET_USER, { variables: { token }})
    let created, lastdate

    useEffect(() => {
      if(profile?.user){
        setConfirm(profile.user.confirm)
        setFio(profile.user.fio)
        setWtsapp(profile.user.wtsapp)
        setEmail(profile.user.email)
        setInn(profile.user.inn)
        setResident(profile.user.resident)
        setOrdersReady(profile.user.ordersReady)
        setFullsReady(profile.user.fullsReady)
        if(profile.user.email.indexOf('@') < 0) setEmailError('укажите свой e-mail')   
      }
    }, [profile])

    if(loading) <p className="request-result"><PropagateLoader color="#392f64" /></p>
    if(error) return <p className="request-result">Ошибка: {error}</p>
    if(!id) logout()
    if(!id) return <Navigate to="/" />
    if(profile){
      created  = format(profile.user.created, 'dd.LL.yyyy [HH:mm]') || ''
      lastdate = format(profile.user.lastdate, 'dd.LL.yyyy [HH:mm]') || ''
      return (
        <>
        {fullsReady.length > 0 && user.group == 5 && <FullsReady visible={isFullsReady} resident={user.resident} data={fullsReady} focus={focusFull} action={showFull} />}
        {fullsReady.length > 0 && user.group == 4 && <StoreFullsReady visible={isFullsReady} data={fullsReady} focus={focusFull} action={showFull} />}
        {ordersReady.length > 0 && user.group == 5 && <DeliveryReady visible={isOrdersReady} resident={user.resident} data={ordersReady} focus={focusOrder} action={showOrder} />}
        {ordersReady.length > 0 && user.group == 4 && <StoresReady visible={isOrdersReady} data={ordersReady} focus={focusOrder} action={showOrder} />}
        <div id="profile"> 
          {!isConfirmMobile && (
            <div className="half-comp-wrap" style={{ animation: `pop-in ${easings.easeOutExpo} 1200ms forwards`}}>
              <div className="head"><h3>Профиль </h3><div className="flex"><aside><RegisterSvg /> {created}</aside><aside><LoginSvg /> {lastdate}</aside></div></div>
              <div className="options">
                  <h3>Ваш статус
                    <span>
                      {profile?.user.group  == 5 && " клиент"}
                      {profile?.user.group  == 1 && " сотрудник склада (Гуанчжоу)"}
                      {profile?.user.group  == 2 && " сотрудник склада (Алматы)"}
                      {profile?.user.group  == 3 && " сотрудник склада (Москва)"}
                      {profile?.user.group  == 4 && " администратор"}
                    </span> 
                  </h3>
                  <div className="message-box">
                    <span className="red">Выполненные заказы</span>
                    <span><u className={isFullsReady} onClick={() => showFullsReady(v => !v)}>Фулфилмент: <b className="grn">{fullsReady.length}</b></u> <u className={isOrdersReady ? "last active" : "last"} onClick={() => showOrdersReady(v => !v)}>Грузы: <b className="grn">{ordersReady.length}</b></u></span>
                  </div> 
              </div>
              <form method="post" className="mb-1" onSubmit={letEdit}>
              <fieldset className="field-wrap ready">
                <select id="resident" name="resident" value={resident} onChange={onChange} required>
                  <option>Страна резидентства</option>
                  <option value="1">Россия</option>
                  <option value="2">Казахстан</option>
                </select>
              </fieldset>
              <fieldset className={fioError == "" && fio != "" ? "field-wrap ready" : "field-wrap"}>
                <input type="text" id="fio" name="fio" value={fio} onChange={onChange} required />
                <label htmlFor="fio">ФИО <EditSvg /></label>
                <aside className={fioError != "" ? "error-field error-active" : "error-field"}>{fioError}</aside>
              </fieldset>
              <fieldset className="field-wrap ready">
                <PhoneInput type="tel" id="mobile" name="mobile" value={profile.user.mobile} disabled="true" required />
                <label htmlFor="mobile">Тел. Мобильный {confirm ? <CheckSvg /> : <><span className="red" onClick={() => setConfirmMobile(v => !v)}>подтвердить</span> <CloseSvg /></>}</label>
              </fieldset>
              <fieldset className={wtsappError == "" && wtsapp != "" ? "field-wrap ready" : "field-wrap"}>
                <PhoneInput type="tel" id="wtsapp" name="wtsapp" value={wtsapp} onChange={onChange} onBlur={checkParam} required />
                <label htmlFor="wtsapp">Тел. Ватсап <EditSvg /></label>
                <aside className={wtsappError != "" ? "error-field error-active" : "error-field"}>{wtsappError}</aside>
              </fieldset>
              <fieldset className="field-wrap ready">
                <input type="email" id="email" name="email" value={email} onChange={onChange} onBlur={checkParam} />
                <label htmlFor="email">E-mail  {emailError ? <CloseSvg /> : <EditSvg />}</label>
                <aside className={emailError != "" ? "error-field error-active" : "error-field"}>{emailError}</aside>
              </fieldset>
              <fieldset className={innError == "" && inn != "" ? "field-wrap ready" : "field-wrap"}>
                <input type="text" id="inn" name="inn" value={inn} onChange={onChange} onBlur={checkParam} autoComplete='off' />
                <label htmlFor="inn">ИНН <EditSvg /></label>
                <aside className={innError != "" && inn != "" ? "error-field error-active" : "error-field"}>{innError}</aside>
              </fieldset>
              <fieldset className={passwordError == "" && password != "" ? "field-wrap ready" : "field-wrap"}>
                <input type="text" id="password" name="password" value={password} onChange={onChange} autoComplete='off' />
                <label htmlFor="password">Новый пароль <EditSvg /></label>
                <aside className={passwordError != "" ? "error-field error-active" : "error-field"}>{passwordError}</aside>
              </fieldset>
              <button className="grn right" type="submit" disabled={isError}>{isRedacted ? 'профиль обновлен' : 'сохранить'} <CheckSvg /></button>
            </form>
          </div>
          )}
          {isConfirmMobile && <Confirm mobile={profile.user.mobile} action={confirmed} />}
        </div>
      </>
      )
    }
}
export default Profile


/*

        {profile.user.ordersReady?.length > 0 && (    
        <div id="delivery" style={{ animation: `slide-in ${easings.easeOutExpo} 900ms forwards`}}>
            <div className="dtable">
              <div className="drow rhead">
                <div className="dhead">создан</div>
                <div className="dhead">номер</div>
                <div className="dhead">пункт</div>
                <div className="dhead">название</div>
                <div className="dhead">доставка</div>
                <div className="dhead">кг</div>	
                <div className="dhead">м3</div>
                <div className="dhead">шт</div>
                <div className="dhead">цена</div>
                <div className="dhead dlast">статус</div>
              </div>

            {profile.user.ordersReady.map(order => (
            <div key={order.id} className={selectedRow == order.track ? "drow drow-active" : "drow"} onClick={() => showOrder(order.track)}>
              <div className="dcell">{String((new Date(order.created)).toLocaleTimeString()).slice(0,-3)} <span>{(new Date(order.created)).toLocaleDateString()}</span></div> 
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell"><span>№</span> {order.track}</div>
              ) : (
                <div className="dcell">{order.track}</div>
              )}
              </Media> 
              <div className="dcell">{order.dest}</div>	 
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell"><span>Груз:</span> {order.title.length > 30 ? order.title.slice(0,30) + ".." : order.title}</div>
              ) : (
                <div className="dcell">{order.title.length > 30 ? order.title.slice(0,30) + ".." : order.title}</div>
              )}
              </Media> 
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell"><span>Доставка:</span> {order.type.length > 30 ? order.type.slice(0,30) + ".." : order.type}</div>
              ) : (
                <div className="dcell">{order.type.length > 30 ? order.type.slice(0,30) + ".." : order.type}</div>
              )}
              </Media> 
              <div className="dcell">{order.weight}</div>
              <div className="dcell">{order.size}</div>
              <div className="dcell">{order.count}</div>
              <div className="dcell">{new Intl.NumberFormat(format, {style: 'currency', currency}).format(order.price)}</div>
              <div className="dcell dlast align-center status"><OrderStatus status={order.status} /></div>
            </div> 
            ))}  
        </div>
      </div>
      )}

      */
