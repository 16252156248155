
import React, {useState} from 'react'
import Bounce from 'react-reveal/Bounce'
import Slide from 'react-reveal/Slide'
import {ReactComponent as PlusSvg} from '../lib/svg/plus.svg'

function OrderButton({actionOrder, actionFull}){
    const [select, setSelect] = useState(false)

    const action = (type = f => {}) => {
          setSelect(false)
          type()
    }

    return (
      <>
      <Bounce right when={!select}>
          <button className={select ? "order false" : "order grn"} onClick={() => setSelect(v => !v)}>новый заказ <PlusSvg /></button>
      </Bounce> 
      <Slide bottom cascade when={select}>
      <ul>
          <li className="deli" onClick={() => action(actionOrder)}>Новый груз</li> 
          <li className="full" onClick={() => action(actionFull)}>Фулфилмент</li> 
      </ul>   
      </Slide> 
      </>
    )
}
export default OrderButton