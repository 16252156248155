import React from 'react'
import ReactDOM from 'react-dom/client'
import {ApolloProvider} from '@apollo/client'
import {BrowserRouter} from 'react-router-dom'
import App from './App'
import client from './client'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
<ApolloProvider client={client}>
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
</ApolloProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onInstall: (registration) => {
    console.log('Service Worker Installed')
  },
  onUpdate: (registration) => {
    console.log('Service Worker Updated')
    registration.waiting.postMessage({type: 'SKIP_WAITING'})
    // window.location.reload()
  }
})


