import React, {useLayoutEffect, useState} from 'react'
import {Link, useNavigate, Navigate} from 'react-router-dom'
import { easings } from 'react-animation'

import {ReactComponent as DeliverySvg} from '../lib/svg/delivery.svg'
import {ReactComponent as CalculatorSvg} from '../lib/svg/calculator.svg'
import {ReactComponent as DesignSvg} from '../lib/svg/design.svg'
import {ReactComponent as FulfillmentSvg} from '../lib/svg/fulfillment.svg'
import {ReactComponent as RegisterSvg} from '../lib/svg/register.svg'

import FormHome from '../comps/FormHome.js'


function Home({action, auth}){
    const loadPage = useNavigate()

    return (
    <div id="home">
      <div className="dtable">
        <div className="drow">
          <div className={auth ? "dcell hidden" : "dcell"}>
              <FormHome action={action} />
              <button className="register" style={{ animation: `fade-in-up ${easings.easeInOutQuint} 800ms forwards`}} onClick={() => loadPage(`/register`)}>регистрация <RegisterSvg /></button>
          </div> 
          <div className="dcell">
            <ul>
              <li style={{ animation: `pop-in ${easings.easeInOutQuint} 800ms forwards`}} onClick={() => loadPage(`/register`)}><span>Доставка</span> <DeliverySvg /></li>
              <li style={{ animation: `pop-in ${easings.easeInOutQuint} 1000ms forwards`}} onClick={() => loadPage(`/calculator`)}><span>Калькулятор</span> <CalculatorSvg /></li>
              <li style={{ animation: `pop-in ${easings.easeInOutQuint} 1200ms forwards`}} onClick={() => loadPage(`/register`)}><span>Дизайн</span> <DesignSvg /></li>
              <li style={{ animation: `pop-in ${easings.easeInOutQuint} 1400ms forwards`}} onClick={() => loadPage(`/register`)}><span>Фулфилмент</span> <FulfillmentSvg /></li>
            </ul>
          </div> 
        </div>  
      </div>            
    </div>
    )
}
export default Home