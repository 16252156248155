import React, {useEffect, useState} from 'react'
import {useQuery, gql } from '@apollo/client'
import PropagateLoader from 'react-spinners/PropagateLoader'
import Fade from 'react-reveal/Fade'
import {ReactComponent as DensitySvg} from '../lib/svg/density.svg'
import {ReactComponent as RubSvg} from '../lib/svg/rub.svg'
import {ReactComponent as KztSvg} from '../lib/svg/kzt.svg'

const PRICE_URL = process.env.REACT_APP_PRICE_URL

export default function OrderPriceCargo({rateCurrency, currency, categoryTitle, category, count, weight, size, price, type, dest}){
    const [isLoad, setLoad] = useState(false),
          [priceList, setPriceList] = useState([])

    useEffect(() => {
      (async () => {
          const file = 'delivery-white'
          const result = await fetch(`${PRICE_URL}/${file}`)
          const data = await result.json()
          setPriceList(data)
          setLoad(true)
          console.log(data)
      })()
    }, [])

    if(!size) return 
    if(isLoad)
    return (
          <Fade bottom when={isLoad}>
              <div className="price-wrap">
                  <div className="calc-result calc-white">
                      <ul>
                        <h3>Параметры груза</h3>
                        <li><span>Категория</span><i></i><b> {categoryTitle}</b></li>
                        <li><span>Мест</span><i></i><b><small>шт</small> {count}</b></li>
                        <li><span>Масса</span><i></i><b><small>кг</small> {weight}</b></li>
                        <li><span>Объем</span><i></i><b><small>м<sup>3</sup></small> {size}</b></li>
                        {/*<li><span>Цена</span><i></i><b>{currency == 'RUB' && <RubSvg />} {currency == 'KZT' && <KztSvg />} {new Intl.NumberFormat(currency).format(price)}</b></li>*/}
                      </ul>

                      <ul>
                        <h3>Стоимость доставки</h3>
                        {dest == 'Москва' ? (
                        <>
                          <li><span>до Москвы </span><i></i><b>{priceList.timeMsk}</b></li>
                          {/*<li><span>Курс <mark>USD/{currency}</mark> </span><i></i><b className="price"><RubSvg /> {rateCurrency}.00 </b></li>*/}
                          <li><span>Коробка <mark>1м3</mark></span><i></i><b className="price">{currency == 'RUB' ? <RubSvg /> : <KztSvg />} {new Intl.NumberFormat(currency).format(priceList.boxMsk * size * rateCurrency)}</b></li>
                          <li><span>Паллет <mark>1м3</mark></span><i></i><b className="price">{currency == 'RUB' ? <RubSvg /> : <KztSvg />} {new Intl.NumberFormat(currency).format(priceList.palletMsk * size * rateCurrency)}</b></li>
                          <li><span>Транзит <mark>1м3</mark></span><i></i><b className="price">{currency == 'RUB' ? <RubSvg /> : <KztSvg />} {new Intl.NumberFormat(currency).format(priceList.transitMsk * size * rateCurrency)}</b></li>
                        </>  
                        ) : (
                        <>
                          <li><span>до Алматы </span><i></i><b>{priceList.timeKzt}</b></li>
                          {/*<li><span>Курс валют <mark>USD/{currency}</mark> </span><i></i><b className="price"><KztSvg /> {rateCurrency}.00 </b></li>*/}
                          <li><span>Коробка <mark>1м3</mark></span><i></i><b className="price">{currency == 'RUB' ? <RubSvg /> : <KztSvg />} {new Intl.NumberFormat(currency).format(priceList.boxKzt * size * rateCurrency)}</b></li>
                          <li><span>Паллет <mark>1м3</mark></span><i></i><b className="price">{currency == 'RUB' ? <RubSvg /> : <KztSvg />} {new Intl.NumberFormat(currency).format(priceList.palletKzt * size * rateCurrency)}</b></li>
                          <li><span>Транзит <mark>1м3</mark></span><i></i><b className="price">{currency == 'RUB' ? <RubSvg /> : <KztSvg />} {new Intl.NumberFormat(currency).format(priceList.transitKzt * size * rateCurrency)}</b></li>
                        </>
                        )}
                     </ul>
                  </div>

                  <p className="note">В случае утери застрахованного груза возвращаем сумму груза полностью. Без страховки возвращаем <b>{2 * rateCurrency} {currency}</b> за 1 кг</p>
                  <p className="note">Стоимость указана только за услуги логистики без дополнительных расходов.</p>
              </div> 
        </Fade>
    )
}
