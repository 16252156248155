
import Bounce from 'react-reveal/Bounce'
import {Link} from 'react-router-dom'
import {ReactComponent as WhatsAppSvg} from '../lib/svg/whatsapp.svg'

function WhatsApp({user}){
    let waLink = `https://api.whatsapp.com/send?phone=77073575262`
    if(user.login) waLink = `https://api.whatsapp.com/send?phone=77073575262&text=Клиент: ${user.client} ${user.login}`
    return (
      <Bounce right opposite>
          <button className="grn whatsapp"><Link to={waLink}><WhatsAppSvg /></Link></button>
      </Bounce>  
    )
}
export default WhatsApp