
import React, {useState, useEffect} from 'react'
import Flip from 'react-reveal/Flip'

import {ReactComponent as ChSvg} from '../lib/svg/China.svg'
import {ReactComponent as KzSvg} from '../lib/svg/Kazakhstan.svg'
import {ReactComponent as RuSvg} from '../lib/svg/Russia.svg'
import {ReactComponent as ArrowsSvg} from '../lib/svg/arrows.svg'

function Time(props){
  const dateNow = new Date(),
        [timeKZ, setTimeKZ] = useState(""),
        [timeCN, setTimeCN] = useState(""),
        [time, setTime] = useState(dateNow.toLocaleTimeString('ru-RU', { timeZone: 'Europe/Moscow' }).slice(0, -3))

  useEffect(() => {
    let hour = time.split(":")[0],
        min = time.split(":")[1],
        time_kz = parseInt(hour) + 2,
        time_cn = parseInt(hour) + 5

    if(time_kz > 23) time_kz = time_kz - 24 
    if(time_cn > 23) time_cn = time_cn - 24    
    if(time_kz < 10) time_kz = 0 + "" + time_kz  
    if(time_cn < 10) time_cn = 0 + "" + time_cn
    time_kz = time_kz + ":" +  min
    time_cn = time_cn + ":" +  min
    setTimeKZ(time_kz)
    setTimeCN(time_cn)
    setTimeout(() => {
      setTime((new Date()).toLocaleTimeString().slice(0, -3))
    }, 60000)
  }, [time])

  return (
      <Flip right opposite>
          <figure>
            <ChSvg />
            <figcaption>{timeCN}</figcaption>
          </figure>
          <ArrowsSvg />
          <figure>
            <KzSvg /> 
            <figcaption>{timeKZ}</figcaption>
          </figure>
          <ArrowsSvg />
          <figure>
            <RuSvg />
            <figcaption>{time}</figcaption>
          </figure>    
      </Flip>  
  )
}
export default Time