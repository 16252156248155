
import React, {useRef, useState, useEffect} from 'react'
import Bounce from 'react-reveal/Bounce'
import { useApolloClient, useQuery, gql } from '@apollo/client'
import PulseLoader from 'react-spinners/PulseLoader'

function StoreStat({group}){
    const apollo = useApolloClient(),
          [completedStores, setCompletedStores] = useState(0),
          [completedFulls, setCompletedFulls] = useState(0)
    let status = Number(group), store = ''
    if     (group == 1) store = 'Гуанчжоу'
    else if(group == 2) store = 'Алматы'
    else if(group == 3) store = 'Москва'
 
    const GET_ORDERS = gql`query getStores($status: Int) {stores(status: $status){id status}fulls{id status}}`
    const {loading,  data} = useQuery(GET_ORDERS, { variables: { status }})

    setTimeout(() => apollo.refetchQueries({include: [GET_ORDERS]}), 60000)

    useEffect(() => {
      if(data?.stores) {
        let completed = 0
        for(let ready of data.stores){
            if(ready.status == 5) ++completed
        }
        setCompletedStores(completed)
        
      }
      if(data?.fulls) {
        let completed = 0
        for(let ready of data.fulls){
            if(ready.status == 7) ++completed
        }
        setCompletedFulls(completed)
      }
    }, [data])

    if(loading) return <PulseLoader color="#392f64" size={10} /> 

    if(data){
      const totalStores = data.stores.length,
            totalFulls  = data.fulls.length
      return (
          <table>
            <tr>
              <td>Склад <b>{store}</b></td><th><b className="red">{totalStores - completedStores}</b> | <b className="grn">{completedStores}</b></th>
              <td><u className="gry">ФФ</u></td><th><b className="red">{totalFulls - completedFulls}</b> | <b className="grn">{completedFulls}</b></th>
            </tr>
          </table> 
      )
    }
}
export default StoreStat