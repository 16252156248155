import React, {useState, useEffect} from 'react'
import { useMutation, useQuery, gql } from '@apollo/client'
import Flip from 'react-reveal/Flip'
import PulseLoader from 'react-spinners/PulseLoader'
import getDayName from '../tools/getDayName.js' 
import getMonthName from '../tools/getMonthName.js' 

import {ReactComponent as PlusSvg} from '../lib/svg/plus.svg'
import {ReactComponent as MinusSvg} from '../lib/svg/minus.svg'
import {ReactComponent as SaveSvg} from '../lib/svg/save.svg'

function Rate({group, rateExport}){
  const dateNow = new Date(),
        [cny, setCNY] = useState(0),
        [rub, setRUB] = useState(0),
        [kzt, setKZT] = useState(0),
        [prevCNY, setPrevCNY] = useState(false),
        [prevRUB, setPrevRUB] = useState(false),
        [prevKZT, setPrevKZT] = useState(false),
        [form, showForm] = useState(false)

  const GET_RATE = gql`query getRate{rate {cny rub kzt cny_up rub_up kzt_up}}`
  const {loading, data} = useQuery(GET_RATE)

  const NEW_RATE = gql`mutation newRate($cny: Float, $rub: Float, $kzt: Float) {newRate(cny: $cny, rub: $rub, kzt: $kzt){cny rub kzt cny_up rub_up kzt_up}}` 
  const [newRate, {}] = useMutation(NEW_RATE, {
      onCompleted: data => {
        setCNY(data.newRate.cny)
        setRUB(data.newRate.rub)
        setKZT(data.newRate.kzt)
        setPrevCNY(data.newRate.cny_up)
        setPrevRUB(data.newRate.rub_up)
        setPrevKZT(data.newRate.kzt_up)
      }
  }) 

  useEffect(() => {
    if(data?.rate && !cny){
      setCNY(data.rate.cny)
      setRUB(data.rate.rub)
      setKZT(data.rate.kzt)
      setPrevCNY(data.rate.cny_up)
      setPrevRUB(data.rate.rub_up)
      setPrevKZT(data.rate.kzt_up)
      rateExport([data.rate.kzt, data.rate.rub])
    }
  }, [data])

  if(loading) return <PulseLoader color="#392f64" />
  return (
      <Flip left opposite>
          <div>1  <span>USD</span></div>
          <div>{cny} <span>CNY</span> {prevCNY ? <b className="font-grn"><PlusSvg /></b> : <b className="font-red"><MinusSvg /></b>}</div>
          <div className="rub">{rub} <span>RUB</span> {prevRUB ? <b className="font-grn"><PlusSvg /></b> : <b className="font-red"><MinusSvg /></b>}</div>
          <div className="kzt">{kzt} <span>KZT</span> {prevKZT ? <b className="font-grn"><PlusSvg /></b> : <b className="font-red"><MinusSvg /></b>}</div>
          <div className={form ? (getDayName(dateNow) == 'Сб' || getDayName(dateNow) == 'Вс' ? 'day-now day-red last last-active' : 'day-now day-green last last-active') : (getDayName(dateNow) == 'Сб' || getDayName(dateNow) == 'Вс' ? 'day-now day-red last' : 'day-now day-green last')} onClick={() => showForm(v => !v)}>
            <b className="day">{dateNow.getDate()} {getMonthName()} {dateNow.getFullYear()}</b> 
            <span className="date">{getDayName(dateNow)}</span>
          </div>
          <form className={form && group == 4 && 'form-active'} method="post" 
                onSubmit={(e) => {
                  e.preventDefault()
                  showForm(v => !v)
                  newRate({variables: {cny: Number(cny), rub: Number(rub), kzt: Number(kzt)}})
                }}>
            <fieldset className="field-wrap ready">
              <input type="number" step="0.01" id="cny" name="cny" value={cny} onChange={(e) => setCNY(e.target.value)} />
              <label htmlFor="kzt">CNY</label>
            </fieldset>
            <fieldset className="field-wrap ready">
              <input type="number" step="0.01" id="rub" name="rub" value={rub} onChange={(e) => setRUB(e.target.value)} />
              <label htmlFor="rub">RUB</label>
            </fieldset>
            <fieldset className="field-wrap ready">
              <input type="number" step="0.01" id="kzt" name="kzt" value={kzt} onChange={(e) => setKZT(e.target.value)} />
              <label htmlFor="kzt">KZT</label>
            </fieldset>
            <button className="grn" type="submit"><SaveSvg /></button>
          </form> 
      </Flip> 
  )
}
export default Rate
