import React, {useState, useEffect, useRef} from 'react'
import { useQuery, gql } from '@apollo/client'
import {Navigate} from 'react-router-dom'
import Shake from 'react-reveal/Shake'
import { easings } from 'react-animation'
import { Parser } from 'html-to-react'
import PropagateLoader from 'react-spinners/PropagateLoader'

import {ReactComponent as CheckSvg} from '../lib/svg/check.svg'
import {ReactComponent as AttentionSvg} from '../lib/svg/attention.svg'
import {ReactComponent as CopySvg} from '../lib/svg/copy.svg'

const ADDRESS_CONFIRM_URL = process.env.REACT_APP_ADDRESS_CONFIRM_URL

function Address({user, pulse, logout, address}){
    useEffect(() => {document.title = 'Профиль :: BQQ Trade House'})
    const selectRef = useRef(),
          id = user.id,
          {client, token} = user,
          [COPY, setCOPY] = useState("..."),
          [isAddress, setAddress] = useState(false)

    const copyField = (text) => {
      try {
        navigator.clipboard.writeText(text)
        setCOPY(`<b>${text}</b> скопировано в буфер обмена!`)
      } catch (err) {
        setCOPY('Ошибка:', err)
      }
    }

    const GET_USER = gql`query User($token: String!) {user(token: $token) {id fio group resident address}}`

    const letAddress = (e, id) => {
      e.preventDefault()
      setAddress(true)
      fetch(`${ADDRESS_CONFIRM_URL}/${id}`)
      .then(data => data.json())
      .then(address())
    } 
    const {loading, data: profile, error} = useQuery(GET_USER, { variables: { token }})

    useEffect(() => {
      if(profile?.user){
        setAddress(profile.user.address)
      }
    }, [profile])

    if(loading) <p className="request-result"><PropagateLoader color="#392f64" /></p>
    if(error) return <p className="request-result">Ошибка: {error}</p>
    if(!id) logout()
    if(!id) return <Navigate to="/" />
    if(profile){
      const clientVal = `${client} BQQ`
      const addressVal = `广东省广州市南沙区创汇谷直街3号小象超市隔壁招商办公室501 室之二BQQ(我不会说中文。 写信给wechat: somsin_ivan)(${client})`
      return (
        <div id="profile"> 
          <div className="half-comp-wrap" style={{ animation: `pop-in ${easings.easeOutExpo} 800ms forwards`}}>
            <div className="head"><h3>Адрес склада</h3><div className="flex">Вам присвоен клиентский номер <span>#{client}</span></div></div>
            <Shake duration="1000" count="10" when={pulse}>
            <form onSubmit={(e) => letAddress(e, profile.user.id)}>
              {!isAddress ? (<>
                <p>Смените адрес доставки груза и подтвердите смену адреса <AttentionSvg /></p>
                <p><a href="https://youtu.be/QYIusSUXKCU?si=pvpoH6ut4nvydFmt" target="_blank"><b>Видео-инструкция по смене адреса</b></a></p></>
              ) : <><p className="grn">Смена адреса подтверждена, спасибо!</p></>}
              <p className="copy">{Parser().parse(COPY)}</p>
              <fieldset className="field-wrap">
                <input type="text" id="client" name="client" value={clientVal} readOnly />
                <CopySvg onClick={() => copyField(clientVal)} /><label htmlFor="client">Имя </label>
              </fieldset>
              <fieldset className="field-wrap">
                <select ref={selectRef} id="countries" onChange={() => copyField(selectRef.current.value)}><option>Провинция</option><option>广东省</option><option>广州市</option><option>南沙区</option><option>南沙街道</option></select>
              </fieldset>
              <fieldset className="field-wrap">
                <input type="text" id="address" name="address" value={addressVal} readOnly />
                <CopySvg onClick={() => copyField(addressVal)} /><label htmlFor="address">Адрес </label>
              </fieldset>
              <fieldset className="field-wrap">
                <input type="text" id="phone" name="phone" value="13265517580" readOnly />
                <CopySvg onClick={() => copyField('13265517580')} /><label htmlFor="phone">Телефон </label>
              </fieldset>
              <fieldset className="field-wrap">
                <input type="text" id="index" name="index" value="510000" readOnly />
                <CopySvg onClick={() => copyField('510000')} /><label htmlFor="index">Почтовый индекс </label>
              </fieldset>
              <button type="submit" className={isAddress ? "yel right" : "grn right"} disabled={isAddress}>адрес сменил <CheckSvg /></button>
            </form>
            </Shake>
          </div>
        </div>
      )
    }
}
export default Address


