
import React, {useRef} from 'react'
import Flip from 'react-reveal/Flip'
import { useQuery, gql } from '@apollo/client'
import PulseLoader from 'react-spinners/PulseLoader'

function OrderStat({client}){
    const GET_FULLS = gql`query getFulls($client: Int!) {fulls(client: $client){status}}`
    const {data: dataFulls} = useQuery(GET_FULLS, { variables: { client: Number(client) }})
    const GET_ORDERS = gql`query getOrders($client: Int!) {orders(client: $client){status}}`
    const {loading, data} = useQuery(GET_ORDERS, { variables: { client: Number(client) }})
    if(loading) return <PulseLoader color="#392f64" size={10} /> 
    let completedOrders = 0
    let totalOrders = data?.orders?.length || 0
    if(totalOrders > 0) for(let order of data.orders) if(order.status == 5) ++completedOrders
    let completedFulls = 0
    let totalFulls = dataFulls?.fulls.length || 0
    if(totalFulls > 0) for(let full of dataFulls.fulls) if(full.status == 7) ++completedFulls
    return (
          <table>
            <tr> 
              <td>грузы</td><th><b className="red">{totalOrders}</b> | <b className="grn">{completedOrders}</b></th>
              <td>фулфилмент</td><th><b className="red">{totalFulls}</b> | <b className="grn">{completedFulls}</b></th>
            </tr>
          </table>
    )

}
export default OrderStat