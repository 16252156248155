import React, {useRef, useState, useEffect} from 'react'
import { useApolloClient, useMutation, useQuery, gql } from '@apollo/client'
import {Link, Navigate} from 'react-router-dom'
import PropagateLoader from 'react-spinners/PropagateLoader'

import OrderPriceCargo from './OrderPriceCargo.js'
import OrderPriceCategory from './OrderPriceCategory.js'
import OrderPriceCargoWhite from './OrderPriceCargoWhite.js'

import Zoom from 'react-reveal/Zoom'

import {ReactComponent as AwaitSvg} from '../lib/svg/await.svg'
import {ReactComponent as CheckSvg} from '../lib/svg/check.svg'
import {ReactComponent as Arrows2Svg} from '../lib/svg/arrows2.svg'

const CHECK_URL = process.env.REACT_APP_CHECK_URL

export default function FormOrder({user, visible, modal, rateExport, orderTrack=0}){
    useEffect(() => {document.title = 'Заказ на доставку груза :: BQQ Trade House'})
    const apollo = useApolloClient(),
          focusRef = useRef(),
          focusRef2 = useRef(),
          scrollRef = useRef(),
          [isOrder, setOrder] = useState(false),
          [isCheck, setCheck] = useState(false),
          [checkStatus, setCheckStatus] = useState(''),
          [userId, setUserId] = useState(user.id),
          [creator, setCreator] = useState(''),
          [editor, setEditor] = useState(''),
          [created, setCreated] = useState(),
          [updated, setUpdated] = useState(),
          [status, setStatus] = useState(0),
          [desc, setDesc] = useState(null),
          [title, setTitle] = useState(null),
          [track, setTrack] = useState(),
          [trackReady, setTrackReady] = useState(track > 0),
          [trackCheck, setTrackCheck] = useState(track > 0),
          [price, setPrice] = useState(0),
          [range, setRange] = useState(0),
          [status_1, setStatus_1] = useState(null),
          [status_2, setStatus_2] = useState(null),
          [status_3, setStatus_3] = useState(null),
          [status_4, setStatus_4] = useState(null),
          [status_5, setStatus_5] = useState(null),
          [nds, setNds] = useState(null),
          [weight, setWeight] = useState(0.001),
          [size, setSize] = useState(0.001),
          [density, setDensity] = useState(10),
          [count, setCount] = useState(1),
          [dest, setDest] = useState('Москва'),
          [type, setType] = useState('BQQ растаможка'),
          [category, setCategory] = useState(0),
          [categoryTitle, setCategoryTitle] = useState(''),
          rateCurrency = user.resident == 1 ? rateExport[1] : rateExport[0],
          currency = user.resident == 1 ? 'RUB' : 'KZT'

    async function checkParam(event){
      let name  = 'track',
          value = track,
          result, data
      result = await fetch(`${CHECK_URL}/${name}/${value}`)
      data   = await result.json()
      if(data.check == 1){
        if(data.param == 'track') {
          setTrackReady(true)
          setTrack(data.track)
          setUserId(data.user)
          setCreator(data.creator)
          setEditor(data.editor)
          setCreated(data.created)
          setUpdated(data.updated)
          setStatus(data.status)
          setCategory(data.category)
          setDesc(data.desc)
          setDest(data.dest)
          setTitle(data.title)
          setPrice(data.price)
          setNds(data.nds)
          setWeight(data.weight)
          setSize(data.size)
          setDensity(Math.ceil(data.weight / data.size))
          setCount(data.count)
          setDest(data.dest)
          setType(data.type)
        }
      }
      else {
        setTrackReady(false)
        setCreator(null)
        setEditor(null)
        setCreated(null)
        setUpdated(null)
        setStatus(0)
        setCategory(0)
        setDesc('')
        setPrice(0)
        setNds(0)
        setDensity(10)
        setCount(1)
        setDest('Москва')
        setType('BQQ растаможка')
        setCheckStatus('проверка пройдена')
        focusRef2.current.focus()
      }
      setTimeout(() => setCheck(false), 3000)
      setTimeout(() => setCheckStatus(null), 4000)
      setTrackCheck(true)
    }   

    const NEW_ORDER = gql`
    mutation newOrder($user: ID, $client: Int, $category: Int, $title: String, $desc: String, $track: String, $price: Float, $nds: Float, $weight: Float, $size: Float, $count: Int, $dest: String, $type: String, $status: Int) {
      newOrder(user: $user, client: $client, category: $category, title: $title, desc: $desc, track: $track, price: $price, nds: $nds, weight: $weight, size: $size, count: $count, dest: $dest, type: $type, status: $status)}` 
    const letOrder = (e) => {
      e.preventDefault()
      newOrder({variables: {user: user.id, client: Number(user.client), category: Number(category), title, desc, track, price: Number(price), weight: Number(weight), size: Number(size), count: Number(count), dest, type, status}})
    } 
    const [newOrder, { loading, error }] = useMutation(NEW_ORDER, {
        onCompleted: data => {
            modal('delivery')
            setOrder(true)
            setTimeout(() => setOrder(false), 1000)
        }
    }) 

  const GET_ORDER = gql`
  query getOrder($track: String) {
    order(track: $track){
      id
      client
      dest
      type
      category
      title
      desc
      track
      weight
      size
      count
      status
      status_1
      status_2
      status_3
      status_4
      status_5
      price
      nds
      cost
      toll
      broker
      created
      customer{
        id
        fio
        client
        inn
        resident
        mobile
        wtsapp
        email
      }
      postedBy{
        id
        fio
        group
        mobile
        wtsapp
        email
      }
    }
  }`
  const {loading: loadingOrder, error: errorOrder, data} = useQuery(GET_ORDER, { variables: { track: orderTrack }})

  useEffect(() => {
    if(data?.order && orderTrack > 0){
      let range = 0
      if(data.order.status_1) range = 20
      if(data.order.status_2) range = 40
      if(data.order.status_3) range = 60
      if(data.order.status_4) range = 80
      if(data.order.status_5) range = 100
      setRange(range)
      setStatus_1(data.order.status_1)
      setStatus_2(data.order.status_2)
      setStatus_3(data.order.status_3)
      setStatus_4(data.order.status_4)
      setStatus_5(data.order.status_5)
      setCreator(data.order.customer.id)
      setEditor(data.order.postedBy.id)
      setCreated(data.order.created)
      setUpdated(data.order.updated)
      setStatus(data.order.status)
      setDesc(data.order.desc)
      setTitle(data.order.title)
      setTrack(data.order.track)
      setTrackReady(true)
      setTrackCheck(true)
      setPrice(data.order.price)
      setNds(data.order.nds)
      setWeight(data.order.weight)
      setSize(data.order.size)
      setDensity(Math.ceil(data.order.weight / data.order.size))
      setCount(data.order.count)
      setDest(data.order.dest)
      setType(data.order.type)
      setCategory(data.order.category)
    //  setCategoryTitle(data.order.)

    }
  }, [data]) 

    useEffect(() => {
      setTimeout(() => focusRef.current.focus(), 1000)
    }, [])    

    useEffect(() => {
      if(size > 0.001 && weight > 0.001) scrollRef.current.scrollIntoView({behavior: 'smooth'})
    }, [size, weight])

    if(isOrder) return <Navigate to="/delivery" />
    return (
    <>
        <div id="form-order">
          <div className="mod-comp-wrap">
            <div className="head"><h3>Новый груз</h3></div>
            <div className="order-range">
            <div className="flex">
              <div className={status_1 != null && "ready"}>Прибыл в Гуанчжоу<br /><span>{status_1 != null ? <>{String((new Date(status_1)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(status_1)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={status_3 != null && "ready"}><Arrows2Svg /></div>
              <div className={status_3 != null && "ready"}>Отправлен в Алматы<br /><span>{status_3 != null ? <>{String((new Date(status_3)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(status_3)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={status_4 != null && "ready"}><Arrows2Svg /></div>
              <div className={status_4 != null && "ready"}>Отправлен в Москву<br /><span>{status_4 != null ? <>{String((new Date(status_4)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(status_3)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={status_5 != null && "ready"}><Arrows2Svg /></div>
              <div className={status_5 != null && "ready"}>Выдан клиенту<br /><span>{status_5 != null ? <>{String((new Date(status_5)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(status_5)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
            </div>
            <meter value={range} max="100" low="40" high="70" optimum="90" />
            </div>
            <form method="post" onSubmit={letOrder}>
                <fieldset className={track ? "field-wrap w-50 mb-2 ready" : "field-wrap w-50 mb-2"}>
                  <input ref={focusRef} type="text" id="track" name="track" value={track} onChange={(e) => {setTrack(e.target.value);setCheck(true)}} disabled={trackReady} required />
                  <label htmlFor="track">Трэк номер <CheckSvg /></label>
                  <aside className={isCheck && track.length > 5 ? "check check-active" : "check"} onClick={checkParam}>{checkStatus ? <span className="grn">{checkStatus}</span> : 'проверить'}</aside>
                </fieldset>
                <fieldset className={title ? "field-wrap w-50 mb-2 ready" : "field-wrap w-50 mb-2"}>
                  <input ref={focusRef2} type="text" id="title" name="title" value={title} onChange={(e) => setTitle(e.target.value)} disabled={trackReady} required />
                  <label htmlFor="title">Краткое наименование груза <CheckSvg /></label>
                </fieldset>
                <OrderPriceCategory trackReady={trackReady} trackCheck={trackCheck} category={category} setCategory={setCategory} setCategoryTitle={setCategoryTitle} />

              {(!category || !track) && <h3 className="info">Укажите Трек-номер и выберите категорию</h3>}

              {trackReady && !orderTrack && (
                <div className="order-ready-info">
                  <h3>Трэк-номер уже добавлен {orderTrack}</h3>
                  <ul>
                    <li><span>Создал</span><i></i><b>{userId == user.id ? 'создан вами' : creator}</b></li>
                    <li><span>Дата создания</span><i></i><b>{(new Date(created)).toLocaleDateString()}  [{(new Date(created)).toLocaleTimeString().slice(0, -3)}] </b></li>
                  </ul>
                  <ul>
                    <li className={!updated && 'disabled'}><span>Обновил</span><i></i><b>{editor ? editor : <br />}</b></li>
                    <li className={!updated && 'disabled'}><span>Дата обновления</span><i></i><b>{updated ? <>{(new Date(updated)).toLocaleDateString()}  [{(new Date(updated)).toLocaleTimeString().slice(0, -3)}]</> : <br />}</b></li>
                  </ul>
                  <ul>
                    <li><span>Статус</span><i></i><b>
                        {status == 0 && <b>Не прибыл на склад</b>}
                        {status == 1 && <b>Прибыл на склад</b>}
                        {status == 3 && <b>Получен в Алматы</b>}
                        {status == 4 && <b>Получен в Москве</b>}</b></li>
                  </ul>
                </div>
              )}

                <fieldset ref={scrollRef} className={!category ? "toggle-wrap disabled" : "toggle-wrap"}>
                <fieldset className={dest != '' ? "radio-wrap short grn" : "radio-wrap short"}>
                  <legend>Пункт назначения {dest}</legend>
                  <div className={dest == 'Москва' && 'active'}><label htmlFor="dest-1">Москва</label> <span><input type="radio" name="dest" id="dest-1" onChange={() => setDest('Москва')} checked={dest == 'Москва'} disabled={!category || trackReady} required /></span></div>
                  <div className={dest == 'Алматы' && 'active'}><label htmlFor="dest-2">Алматы</label> <span><input type="radio" name="dest" id="dest-2" onChange={() => setDest('Алматы')} checked={dest == 'Алматы'} disabled={!category || trackReady} required /></span></div>
                </fieldset>
                <fieldset className={type != '' ? "radio-wrap long grn" : "radio-wrap long"}>
                  <legend>Вид доставки</legend>
                  <div className={type == 'BQQ растаможка' ? "w-30 active" : "w-30"}><label htmlFor="type-1">BQQ растаможка КАРГО</label> <span><input type="radio" name="type" id="type-1" onChange={() => setType('BQQ растаможка')} checked={type == 'BQQ растаможка'} disabled={!category || trackReady} required /></span></div>
                  <div className={type == 'Собственная растаможка' ? "w-70 active" : "w-70"}><label htmlFor="type-2">Собственная растаможка БЕЛАЯ ЛОГИСТИКА</label> <span><input type="radio" name="type" id="type-2" onChange={() => setType('Собственная растаможка')} checked={type == 'Собственная растаможка'} disabled={!category || trackReady} required /></span></div>
                </fieldset>
              </fieldset>

              <fieldset className={!category ? "fieldset-wrap disabled" : "fieldset-wrap"}>
                <fieldset className={desc ? "field-wrap long-wrap ready" : "field-wrap long-wrap"}>
                  <textarea id="desc" name="desc" value={desc} onChange={(e) => setDesc(e.target.value)} disabled={!category || trackReady} required />
                  <label htmlFor="desc">Комментарий и описание груза <CheckSvg /></label>
                </fieldset>
              </fieldset> 
              
                <fieldset className={!category ? "fieldset-wrap mt-3 disabled" : "fieldset-wrap mt-3"}>

                <div className="flex reversed">
                <fieldset className={count ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="count" name="count" value={count} onChange={(e) => setCount(e.target.value)} autoComplete="off" disabled={!category || trackReady} required />
                    <label htmlFor="count">Кол-во мест, шт <CheckSvg /></label>
                </fieldset>
                <fieldset className={price ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="price" name="price" value={price} onChange={(e) => setPrice(e.target.value)} autoComplete="off" disabled={!category || trackReady} required />
                    <label htmlFor="price">Цена груза в {currency} {/*nds && <>+ {nds} НДС</>*/}<CheckSvg /></label>
                </fieldset>
                </div>
                <div className="flex">
                <fieldset className={size ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="size" name="size" step="0.001" value={size} onChange={(e) => {setSize(e.target.value);setDensity(parseInt(weight / e.target.value));}} autoComplete="off" disabled={!category || trackReady} required />
                    <label htmlFor="size">Объем, м<sup>3</sup> <CheckSvg /></label>
                </fieldset>
                <fieldset className={weight ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="weight" name="weight" step="0.001" value={weight} onChange={(e) => {setWeight(e.target.value);setDensity(parseInt(e.target.value / size));}} autoComplete="off" disabled={!category || trackReady} required />
                    <label htmlFor="weight">Масса, кг <CheckSvg /></label>
                </fieldset>
                </div>
                <div className="flex flex-end">
                    <div>1</div>
                    <button className="grn" type="submit" disabled={trackReady}>{trackReady ? <>заказ сформирован <CheckSvg /></> : <>Создать заказ <AwaitSvg /></>}</button>
                </div>
              </fieldset> 

            </form>
          </div> 
        </div>
    {/*type == 'BQQ растаможка' &&  size > 0.001 && weight > 0.001 && <OrderPriceCargo rateCurrency={rateCurrency} currency={currency} categoryTitle={categoryTitle} category={category} weight={weight} size={size} count={count} density={density} />}
    {type == 'Собственная растаможка' &&  <OrderPriceCargoWhite rateCurrency={rateCurrency} currency={currency} categoryTitle={categoryTitle} category={category} weight={weight} size={size} count={count} type={type} price={price} />*/}
    </>
    )
}
