import React, {useState} from 'react'
import axios from 'axios'
import Resizer from 'react-image-file-resizer'

import {ReactComponent as SaveSvg} from '../lib/svg/save.svg'

const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL

export default function Uploader({returnURL}) {
  const [uploads, setUploads] = useState([]),
        image = new Set(), preview = new Set()

  const resizeFile = (file) => new Promise(resolve => {
          Resizer.imageFileResizer(file, 550, 400, 'JPEG', 50, 0,
          uri => {
            resolve(uri);
          }, 'base64' );
  })


  const letUpload = (e) => {
    e.preventDefault()
    axios.post(UPLOAD_URL, image, {
        headers: {'Content-Type': 'multipart/form-data'}
    })
    .then(data => data)
    .then(resp => returnURL(resp.data))
  }

  const showUploads = async (e) => {
    let len = e.target.files.length
    if (len > 3) len = 3
    for(let i = 0; i < len; ++i){
        const file = e.target.files[i]
        const updt = await resizeFile(file)
        image.add(updt)
        preview.add(e.target.files[i])
    }
    returnURL(Array.from(image))
    setUploads(Array.from(preview))
  }

  return (
    <>
    <form>
              <fieldset className="field-wrap">
                <input type="file" id="foto" name="foto" accept="image/*" multiple onChange={showUploads} />
                <label htmlFor="foto">Фото</label>
              </fieldset>
              <fieldset className="field-wrap field-foto">
                {/*uploads && uploads.length > 0 && <button className="grn" type="submit"><SaveSvg /></button>*/}
                {uploads.map((file, index) => (
                  <img key={index} src={URL.createObjectURL(file)} />
                ))}
              </fieldset>
    </form>          
        
    </>

  )
}