import React, {useRef, useState, useEffect} from 'react'
import { useMutation, useQuery, gql } from '@apollo/client'
import {Link, Navigate} from 'react-router-dom'
import PropagateLoader from 'react-spinners/PropagateLoader'
import Zoom from 'react-reveal/Zoom'

import OrderPriceCargo from './OrderPriceCargo.js'
import OrderPriceCategory from './OrderPriceCategory.js'
import OrderPriceCargoWhite from './OrderPriceCargoWhite.js'

import {ReactComponent as SaveSvg} from '../lib/svg/save.svg'
import {ReactComponent as AwaitSvg} from '../lib/svg/await.svg'
import {ReactComponent as CheckSvg} from '../lib/svg/check.svg'

const CHECK_URL  = process.env.REACT_APP_CHECK_URL

export default function FormStore({user, modal, rateExport}){
    useEffect(() => {document.title = 'Заказ на доставку груза :: BQQ Trade House'})
    const focusRef = useRef(),
          scrollRef = useRef(),
          [isOrder, setOrder] = useState(false),
          [id, setId] = useState(''),
          [isCheck, setCheck] = useState(''),
          [checkStatus, setCheckStatus] = useState(''),
          [userId, setUserId] = useState(user.id),
          [creator, setCreator] = useState(''),
          [editor, setEditor] = useState(''),
          [created, setCreated] = useState(),
          [updated, setUpdated] = useState(),
          [status, setStatus] = useState(''),
          [desc, setDesc] = useState(null),
          [title, setTitle] = useState(null),
          [comment, setComment] = useState(null),
          [track, setTrack] = useState(),
          [trackReady, setTrackReady] = useState(track > 0),
          [client, setClient] = useState(''),
          [price, setPrice] = useState(0),
          [nds, setNds] = useState(0),
          [weight, setWeight] = useState(0.001),
          [size, setSize] = useState(0.001),
          [count, setCount] = useState(1),
          [dest, setDest] = useState('Москва'),
          [tarif, setTarif] = useState(1),
          [tarifs, setTarifs] = useState({std: 0, exp: 0, odn: 0}),
          [type, setType] = useState('BQQ растаможка'),
          [currency, setCurrency] = useState(''),
          [rateCurrency, setRateCurrency] = useState(''),
          [format, setFormat] = useState(''),
          [ndsTax, setNdsTax] = useState(null),
          [toll, setToll] = useState(0),
          [cost, setCost] = useState(0),
          [broker, setBroker] = useState(0),
          [trans, setTrans] = useState(0),
          [carrier, setCarrier] = useState(null),
          [carcont, setCarcont] = useState(null),
          [total, setTotal] = useState(0),
          [isError, setError] = useState(false),
          [trackError, setTrackError] = useState(''),
          [category, setCategory] = useState(0),
          [categoryTitle, setCategoryTitle] = useState(''),
          [density, setDensity] = useState(10)
      
    const onChange = event => {
        if(event.target.name == 'size'){
          setSize(event.target.valueAsNumber)
          setDensity(parseInt(weight / event.target.valueAsNumber))
        }
        else if(event.target.name == 'weight'){
          setWeight(event.target.valueAsNumber)
          setDensity(parseInt(event.target.valueAsNumber / size))
        }
        else if(event.target.name == 'price') setPrice(event.target.valueAsNumber)
        else if(event.target.name == 'nds')   setNds(event.target.valueAsNumber)
        else if(event.target.name == 'count') setCount(event.target.valueAsNumber)
        else if(event.target.name == 'dest')  setDest(event.target.value)
        else if(event.target.name == 'type')  setType(event.target.value)
        else if(event.target.name == 'desc')  setDesc(event.target.value)
        else if(event.target.name == 'title') setTitle(event.target.value)
        else if(event.target.name == 'comment')setComment(event.target.value)
        else if(event.target.name == 'track') setTrack(event.target.value)
        else if(event.target.name == 'client')setClient(event.target.value)
        else if(event.target.name == 'toll')  setToll(event.target.valueAsNumber)
        else if(event.target.name == 'cost')  setCost(event.target.valueAsNumber)
        else if(event.target.name == 'broker')setBroker(event.target.valueAsNumber)
        else if(event.target.name == 'trans') setTrans(event.target.valueAsNumber)
        else if(event.target.name == 'carrier')setCarrier(event.target.value)
        else if(event.target.name == 'carcont')setCarcont(event.target.value)
        else if(event.target.name == 'status')setStatus(event.target.value)
        else if(event.target.name == 'tarif') {
          console.log(tarifs, trans)
          let value = event.target.value
          setTarif(value)
          if(value == 1) setTrans(parseFloat(tarifs.std * weight).toFixed(2))
          if(value == 2) setTrans(parseFloat(tarifs.exp * weight).toFixed(2))
          if(value == 3) setTrans(parseFloat(tarifs.odn * weight).toFixed(2))      
        }  
    }

    async function checkParam(){
      let name  = 'track',
          value = track,
          result, data
      result = await fetch(`${CHECK_URL}/${name}/${value}`)
      data   = await result.json()
      if(data.check == 1){
        if(data.param == 'track') {
          setTrackReady(true)
          setUserId(data.user)
          setCreator(data.creator)
          setEditor(data.editor)
          setCreated(data.created)
          setUpdated(data.updated)
          setId(data.id)
          setTrack(data.track)
          setStatus(data.status)
          setCategory(data.category)
          setDesc(data.desc)
          setDest(data.dest)
          setTarif(data.tarif)
          setTitle(data.title)
          setComment(data.comment)
          setPrice(data.price)
          setNds(data.nds)
          setWeight(data.weight)
          setSize(data.size)
          setDensity(Math.ceil(data.weight / data.size))
          setCount(data.count)
          setClient(data.client)
          setToll(data.toll)
          setCost(data.cost)
          setBroker(data.broker)
          setTrans(data.trans)
          setCarrier(data.carrier)
          setCarcont(data.carcont)
          setTotal(Number(data.toll) + Number(data.cost) + Number(data.broker) + Number(data.trans))
        }
      }
      else{
        setTrackReady(false)
        setCreator(null)
        setEditor(null)
        setCreated(null)
        setUpdated(null)
        setStatus(0)
        setDesc('')
        setPrice(0)
        setNds(0)
        setWeight(0.001)
        setSize(0.001)
        setDensity(10)
        setCount(1)
        setDest('Москва')
        setType('BQQ растаможка')
      }
      setTimeout(() => setCheck(false), 3000)
      setTimeout(() => setCheckStatus(null), 4000)
    }   

    const NEW_ORDER = gql`
    mutation newOrder($user: ID, $client: Int, $category: Int, $title: String, $comment: String, $desc: String, $track: String, $price: Float, $nds: Float, $weight: Float, $size: Float, $count: Int, $dest: String, $tarif: Int, $type: String, $cost: Float, $toll: Float, $trans: Float, $broker: Float, $carrier: String, $carcont: String, $status: Int) {
      newOrder(user: $user, client: $client, category: $category, title: $title, comment: $comment, desc: $desc, track: $track, price: $price, nds: $nds, weight: $weight, size: $size, count: $count, dest: $dest, tarif: $tarif, type: $type, cost: $cost, toll: $toll, trans: $trans, broker: $broker, carrier: $carrier, carcont: $carcont, status: $status)}` 

    const letOrder = (e) => {
      e.preventDefault()
      newOrder({variables: {client: Number(client.split(' | ')[0]), category: Number(category), title, comment, desc, track, price: Number(price), nds: Number(nds), weight: Number(weight), size: Number(size), count: Number(count), dest, tarif: Number(tarif), type, cost: Number(cost), toll: Number(toll), trans: Number(trans), broker: Number(broker), carrier, status: Number(status), carcont, user: user.id}})
    } 

    const [newOrder, { loading, error }] = useMutation(NEW_ORDER, {
        onCompleted: data => {
            modal()
            setOrder(true)
            setTimeout(() => setOrder(false), 1000)
        }
    }) 
 
    const GET_CLIENTS = gql`
    query getClients {clients{fio client resident}}`
    const {data} = useQuery(GET_CLIENTS)

    const GET_TRACKS = gql`
    query getTracks {orders{client track customer {resident}}}`
    const {data: dataTracks} = useQuery(GET_TRACKS)

    useEffect(() => {
      if(client != ''){
        setFormat(client.split(' | ')[1] == 1 ? 'ru-RU' : 'kz-KZ')
        setCurrency(client.split(' | ')[1] == 1 ? 'RUB' : 'KZT')
        setRateCurrency(client.split(' | ')[1] == 1 ? rateExport[1] : rateExport[0])
        setNdsTax(client.split(' | ')[1] == 1 ? .2 : .12)
      }
    }, [client])

    useEffect(() => {
        setTotal(Number(toll) + Number(cost) + Number(broker) + Number(trans))
    }, [toll, cost, broker, trans]) 

    useEffect(() => {
      if(size > 0.001 && weight > 0.001 && client) scrollRef.current.scrollIntoView({behavior: 'smooth'})
    }, [size, weight, type, client])

    

    if(loading) return <p className="request-result"><PropagateLoader color="#392f64" /></p>
    if(error) return <p className="request-result">Ошибка: {error}</p>
    if(isOrder) return <Navigate to="/store" />
    return (
    <>
        <div id="form-order">
          <div className="mod-comp-wrap">
            <div className="head">
              <h3>Добавить груз на склад</h3>
            </div>
            <OrderPriceCategory trackReady={false} trackCheck={true} category={category} setCategory={setCategory} setCategoryTitle={setCategoryTitle} />
            <form method="post" onSubmit={letOrder}>
              <fieldset className={!trackError && track ? "field-wrap w-50 ready" : "field-wrap w-50"}>
                <input ref={focusRef} type="text" list="tracks" id="track" name="track" value={track} onChange={(e) => {setTrack(e.target.value);setCheck(true)}} autoComplete='off' required />
                <label htmlFor="track">Трэк номер <CheckSvg /></label>
                <aside className={trackError != "" ? "error-field error-active" : "error-field"}>{trackError}</aside>
                <datalist id="tracks">{dataTracks && dataTracks.orders.map(track => <option value={track.track} label={track.client +" | "+ track.customer?.resident || 1} />)}</datalist>
                <aside className={isCheck && track.length > 5 ? "check check-active" : "check"} onClick={checkParam}>{checkStatus ? <span className="grn">{checkStatus}</span> : 'проверить'}</aside>
              </fieldset>
              <fieldset className={status == 0 ? "field-wrap w-50" : "field-wrap w-50 ready"}>
                  <select id="status" name="status" value={status} onChange={onChange} required>
                    <option value="0">Не прибыл на склад</option>
                    <option value="1">Прибыл в Гуанчжоу</option>
                    <option value="3">Отправлен в Алматы</option>
                    <option value="4">Отправлен в Москву</option>
                    <option value="5">Выдан клиенту</option>
                  </select>
                  <label htmlFor="carrier">Статус доставки</label>
                </fieldset>

                {(!category || !track) && !trackReady && <h3 className="info">Укажите Трек-номер и выберите категорию</h3>}

                {trackReady && (
                <div className="order-ready-info">
                  <h3>Трэк-номер уже добавлен </h3>
                  <ul>
                    <li><span>Создал</span><i></i><b>{userId == user.id ? 'создан вами' : creator}</b></li>
                    <li><span>Дата</span><i></i><b>{(new Date(created)).toLocaleDateString()}  [{(new Date(created)).toLocaleTimeString().slice(0, -3)}] </b></li>
                  </ul>
                  <ul>
                    <li className={!updated && 'disabled'}><span>Обновил</span><i></i><b>{editor ? editor : <br />}</b></li>
                    <li className={!updated && 'disabled'}><span>Дата</span><i></i><b>{updated ? <>{(new Date(updated)).toLocaleDateString()}  [{(new Date(updated)).toLocaleTimeString().slice(0, -3)}]</> : <br />}</b></li>
                  </ul>
                  <ul>
                    <li><span>Статус</span><i></i><b>
                        {status == 0 && <b>Не прибыл на склад</b>}
                        {status == 1 && <b>Прибыл на склад</b>}
                        {status == 3 && <b>Получен в Алматы</b>}
                        {status == 4 && <b>Получен в Москве</b>}</b></li>
                  </ul>
                </div>
              )}
 
 
              <fieldset className={dest != '' ? "radio-wrap short grn" : "radio-wrap short"}>
                <legend>Пункт назначения</legend>
                <div className={dest == 'Москва' && 'active'}><label htmlFor="dest-1">Москва</label> <span><input type="radio" name="dest" id="dest-1" value="Москва" checked={dest == 'Москва'} onChange={onChange} required /></span></div>
                <div className={dest == 'Алматы' && 'active'}><label htmlFor="dest-2">Алматы</label> <span><input type="radio" name="dest" id="dest-2" value="Алматы" checked={dest == 'Алматы'} onChange={onChange} required /></span></div>
              </fieldset>
              <fieldset className={type != '' ? "radio-wrap long grn" : "radio-wrap long"}>
                <legend>Вид доставки</legend>
                <div className={type == 'BQQ растаможка' ? "w-30 active" : "w-30"}><label htmlFor="type-1">BQQ растаможка КАРГО</label> <span><input type="radio" name="type" id="type-1" value="BQQ растаможка" checked={type == 'BQQ растаможка'} onChange={onChange} required /></span></div>
                <div className={type == 'Собственная растаможка' ? "w-70 active" : "w-70"}><label htmlFor="type-2">Собственная растаможка БЕЛАЯ ЛОГИСТИКА</label> <span><input type="radio" name="type" id="type-2" value="Собственная растаможка" checked={type == 'Собственная растаможка'} onChange={onChange} required /></span></div>
              </fieldset>
              <fieldset className="fieldset-wrap">
                <fieldset className={title ? "field-wrap mb-2 ready" : "field-wrap mb-2"}>
                  <input type="text" id="title" name="title" value={title} onChange={onChange} autoComplete='off' required />
                  <label htmlFor="title">Краткое наименование груза <CheckSvg /></label>
                </fieldset>
                <fieldset className={desc ? "field-wrap long-wrap ready" : "field-wrap long-wrap"}>
                  <textarea ref={scrollRef} className="store-sm" id="desc" name="desc" value={desc} onChange={onChange} required />
                  <label htmlFor="desc">Комментарий и описание груза <CheckSvg /></label>
                </fieldset>
                <fieldset className={carrier ? "field-wrap ready" : "field-wrap"}>
                  <input type="text" id="carrier" name="carrier" value={carrier} onChange={onChange} autoComplete='off' />
                  <label htmlFor="carrier">Перевозчик <CheckSvg /></label>
                </fieldset>
                <fieldset className={carcont ? "field-wrap ready" : "field-wrap"}>
                  <input type="text" id="carcont" name="carcont" value={carcont} onChange={onChange} autoComplete='off' />
                  <label htmlFor="carcont">Контакт перевозчика <CheckSvg /></label>
                </fieldset>
                <fieldset className={comment ? "field-wrap ready" : "field-wrap"}>
                  <input type="text" id="comment" name="comment" value={comment} onChange={onChange} autoComplete='off' />
                  <label htmlFor="comment">Служебный комментарий <CheckSvg /></label>
                </fieldset>
              </fieldset> 

              <fieldset className="fieldset-wrap">
                <div className="flex">
                  <fieldset className={client == "" ? "field-wrap sm" : "field-wrap sm ready"}>
                    <input className="client" list="clients" type="text" id="client" name="client" value={client} onChange={onChange} autoComplete='off' required />
                    <label htmlFor="client">Клиент <CheckSvg /></label>
                    <datalist id="clients">{data?.clients?.map(client => <option value={client.client +" | "+ client.resident} label={client.fio} />)}</datalist>
                  </fieldset>
                  <fieldset className={nds ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="nds" name="nds" value={nds} onChange={onChange} autoComplete="off" required />
                    <label htmlFor="nds">НДС {currency}<CheckSvg /></label>
                  </fieldset>
                </div>
                <div className="flex reversed">
                <fieldset className={count ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="count" name="count" value={count} onChange={onChange} autoComplete="off" required />
                    <label htmlFor="count">Количество мест <CheckSvg /></label>
                </fieldset>
                <fieldset className={price ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="price" name="price" value={price} onChange={onChange} autoComplete="off" required />
                    <label htmlFor="price">Цена груза в {currency}<CheckSvg /></label>
                </fieldset>
                </div>
                <div className="flex">
                <fieldset className={size ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="size" name="size" step="0.001" value={size} onChange={onChange} autoComplete="off" required />
                    <label htmlFor="size">Объем, м3 <CheckSvg /></label>
                </fieldset>
                <fieldset className={weight ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="weight" name="weight" step="0.001" value={weight} onChange={onChange} autoComplete="off" required />
                    <label htmlFor="weight">Масса, кг <CheckSvg /></label>
                </fieldset>
                </div>

                <div className="flex">
                <fieldset className={trans ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="trans" name="trans" value={trans} onChange={onChange} autoComplete="off" required />
                    <label htmlFor="trans">Цена доставки USD <CheckSvg /></label>
                </fieldset>
                <fieldset className={cost ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="cost" name="cost" value={cost} onChange={onChange} autoComplete="off" required />
                    <label htmlFor="cost">Цена себестоимости USD <CheckSvg /></label>
                </fieldset>
                </div>
                <div className="flex">
                <fieldset className={toll ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="toll" name="toll" value={toll} onChange={onChange} autoComplete="off" required />
                    <label htmlFor="toll">Таможенные пошлины USD <CheckSvg /></label>
                </fieldset>
                <fieldset className={broker ? "field-wrap sm ready" : "field-wrap sm"}>
                    <input type="number" id="broker" name="broker" value={broker} onChange={onChange} autoComplete="off" required />
                    <label htmlFor="broker">Услуги брокера USD <CheckSvg /></label>
                </fieldset>
                </div>
                {type == 'BQQ растаможка' &&
                <fieldset className={dest != '' ? "radio-wrap tarif grn" : "radio-wrap tarif"}>
                  <legend>Доступные маршруты</legend>
                  <div className={tarifs.std ? tarif == '1' && 'active' : 'disabled'}><label htmlFor="tarif-1">Стандарт</label> <span><input type="radio" name="tarif" id="tarif-1" value="1" checked={tarif == '1'} onChange={onChange} disabled={!tarifs.std} /></span></div>
                  <div className={tarifs.exp ? tarif == '2' && 'active' : 'disabled'}><label htmlFor="tarif-2">Экспресс</label> <span><input type="radio" name="tarif" id="tarif-2" value="2" checked={tarif == '2'} onChange={onChange} disabled={!tarifs.exp} /></span></div>
                  <div className={tarifs.odn ? tarif == '3' && 'active' : 'disabled'}><label htmlFor="tarif-3">Однотип</label> <span><input type="radio" name="tarif" id="tarif-3" value="3" checked={tarif == '3'} onChange={onChange} disabled={!tarifs.odn} /></span></div>
                </fieldset>
                }
                <div className="flex flex-end">
                <div><p className="total"><b>{format != '' && total > 0 &&  new Intl.NumberFormat(format, {style: 'currency', currency}).format(total * rateCurrency)}</b></p></div>
                <button className="grn" type="submit" disabled={trackReady}>{trackReady ? <>заказ сформирован <CheckSvg /></> : <>Создать заказ <AwaitSvg /></>}</button>
                </div>
              </fieldset> 

            </form>
          </div> 
        </div>
    {type == 'BQQ растаможка' && size > 0.001 && weight > 0.001 && currency && track && client && <OrderPriceCargo store={true} rateCurrency={rateCurrency} categoryTitle={categoryTitle} category={category} currency={currency} weight={weight} size={size} count={count} density={density} setTarifs={setTarifs} setTrans={setTrans} />}
    {type == 'Собственная растаможка' && currency && track && client && <OrderPriceCargoWhite dest={dest} rateCurrency={rateCurrency} currency={currency} categoryTitle={categoryTitle} category={category} weight={weight} size={size} count={count} type={type} price={price} />}

    </>
    )
}
