import React, {useState, useEffect} from 'react'
import { useQuery, gql } from '@apollo/client'
import PropagateLoader from 'react-spinners/PropagateLoader'

import {ReactComponent as Arrows2Svg} from '../lib/svg/arrows2.svg'

function Full({track}){
  const [range, setRange] = useState(0)
  const GET_FULL = gql`
  query getFull($track: String) {
    full(track: $track){
      id
      created
      updated
      user
      edited
      client
      title
      dest
      address
      track
      count
      comment
      status
      status_1
      status_2
      status_3
      status_4
      status_5
      status_6
      status_7
      status_8
      pack_1
      pack_2
      pack_3
      pack_4
      pack_5
      pack_6
      pack_7
      pack_7_1
      pack_7_2
      pack_7_3
      pack_8
      pack_8_1
      pack_8_2
      pack_8_3
      pack_8_4
      pack_8_5
      pack_8_6
      pack_8_7
      pack_8_8
      pack_8_9
      pack_8_10
      pack_8_11
      pack_8_12
      pack_9
      pack_9_1
      pack_9_2
      pack_9_3
      pack_9_4
      pack_9_5
      pack_9_6
      pack_9_7
      pack_10
      pack_10_1
      pack_10_2
      pack_11
      pack_12
      pack_13
      pack_14
      pack_15
      pack_16
      pack_17
      pack_18
      pack_19
      pack_20
      pack_21
      pack_22
      pack_23
      total
      image
      customer{
        fio
        client
        inn
        resident
        mobile
        wtsapp
        email
      }
      postedBy{
        fio
        group
        mobile
        wtsapp
        email
      }      
    }
  }`
  const {loading, error, data} = useQuery(GET_FULL, { variables: { track }})

  useEffect(() => {
    if(data?.full){
      let range = 0
      if(data.full.status_1) range = 10
      if(data.full.status_2) range = 20
      if(data.full.status_3) range = 30
      if(data.full.status_4) range = 50
      if(data.full.status_5) range = 60
      if(data.full.status_6) range = 80
      if(data.full.status_7) range = 100
      setRange(range)
    }
  }, [data])

  if(loading) return <p className="request-result"><PropagateLoader color="#392f64" /></p>
  if(error) return <p className="request-result">Ошибка: {error}</p>
  if(data?.full){
    const currency = data.full?.customer.resident== 1 ? 'RUB' : 'KZT'
    const format   = data.full?.customer.resident == 1 ? 'ru-RU' : 'kz-KZ'
    return (
    <div id="order">
        <div className="head">
            <h3>Фулфилмент <span>#{data.full.track}</span> <b>{data.full.title}</b></h3>
        </div>
        <div className="body">
          <div className="order-range">
            <div className="flex">
              <div className={data.full.status_1 != null && "ready"}>Связались<br /><span>{data.full.status_1 != null ? <>{String((new Date(data.full.status_1)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(data.full.status_1)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={data.full.status_2 != null && "ready"}><Arrows2Svg /></div>
              <div className={data.full.status_2 != null && "ready"}>Оплачено<br /><span>{data.full.status_2 != null ? <>{String((new Date(data.full.status_2)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(data.full.status_2)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={data.full.status_3 != null && "ready"}><Arrows2Svg /></div>
              <div className={data.full.status_3 != null && "ready"}>Сборка<br /><span>{data.full.status_3 != null ? <>{String((new Date(data.full.status_3)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(data.full.status_3)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={data.full.status_4 != null && "ready"}><Arrows2Svg /></div>
              <div className={data.full.status_4 != null && "ready"}>Готово<br /><span>{data.full.status_4 != null ? <>{String((new Date(data.full.status_4)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(data.full.status_4)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={data.full.status_5 != null && "ready"}><Arrows2Svg /></div>
              <div className={data.full.status_5 != null && "ready"}>Отправлено<br /><span>{data.full.status_5 != null ? <>{String((new Date(data.full.status_5)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(data.full.status_5)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={data.full.status_6 != null && "ready"}><Arrows2Svg /></div>
              <div className={data.full.status_6 != null && "ready"}>Принято<br /><span>{data.full.status_5 != null ? <>{String((new Date(data.full.status_6)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(data.full.status_6)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
              <div className={data.full.status_7 != null && "ready"}><Arrows2Svg /></div>
              <div className={data.full.status_7 != null && "ready"}>Завершено <br /><span>{data.full.status_6 != null ? <>{String((new Date(data.full.status_7)).toLocaleTimeString()).slice(0,-3)} <small>{(new Date(data.full.status_7)).toLocaleDateString()}</small></> : "ожидается"}</span></div>
            </div>
            <meter value={range} max="100" low="40" high="70" optimum="90" />
          </div>
        <div className="flex images">
          {(data.full.image.length > 0) && data.full.image.map(img => <img key={img} src={img} />)}  
        </div>  
        <div className="flex">
        <div className="wrap">
          <ul>
            <h3>Получатель</h3>
            <li className="client">{data.full.customer.fio}</li>
            <li><span>Клиент</span><i></i><b>{data.full.customer.client}</b></li>
            <li><span>Резидент</span><i></i><b>{data.full.customer.resident == 1 ? "Россия" : "Казахстан"}</b></li>
            <li><span>ИНН</span><i></i><b>{data.full.customer.inn}</b></li>
            <li><span>Тел.мобильный</span><i></i><b><a href={`tel:${data.full.customer.mobile}`}>{data.full.customer.mobile}</a></b></li>
            <li><span>What`s App</span><i></i><b><a href={`https://api.whatsapp.com/send?phone=${data.full.customer.wtsapp.replace(/\+/g, '').replace(/\(/g, '').replace(/\)/g, '').replaceAll(/\-/g, '')}`}>{data.full.customer.wtsapp}</a></b></li>
            <li><span>E-mail</span><i></i><b>{data.full.customer.email}</b></li>
          </ul>
          <ul>
            <h3>Оформил</h3>
            <li><span>ФИО</span><i></i><b className="client">{data.full.postedBy.fio}</b></li>
            <li><span>Группа</span><i></i><b>{data.full.postedBy.group == 5 ? "клиент" : "сотрудник"}</b></li>
            <li><span>Тел.мобильный</span><i></i><b><a href={`tel:${data.full.postedBy.mobile}`}>{data.full.postedBy.mobile}</a></b></li>
            <li><span>What`s App</span><i></i><b><a href={`https://api.whatsapp.com/send?phone=${data.full.postedBy.wtsapp.replace(/\+/g, '').replace(/\(/g, '').replace(/\)/g, '').replaceAll(/\-/g, '')}`}>{data.full.postedBy.wtsapp}</a></b></li>
            <li><span>E-mail</span><i></i><b>{data.full.postedBy.email}</b></li>
          </ul>
          <ul>
            <h3>Груз</h3>
            <li><span>Трэк номер</span><i></i><b>{data.full.track}</b></li>
            <li><span>Статус доставки</span><i></i><b>
              {data.full.status == 0 && <b>Новая заявка</b>}
              {data.full.status == 1 && <b>Связались с клиентом</b>}
              {data.full.status == 2 && <b>Оплачено</b>}
              {data.full.status == 3 && <b>Идет сборка</b>}
              {data.full.status == 4 && <b>Готово к отправке</b>}
              {data.full.status == 5 && <b>Отправлено</b>}
              {data.full.status == 6 && <b>Принято на склад МП</b>}
              {data.full.status == 7 && <b>Завершено</b>}
            </b></li>
            <li><span>Название</span><i></i><b>{data.full.title.length > 30 ? data.full.title.slice(0,30) + ".." : data.full.title}</b></li>
            <li><span>Мест</span><i></i><b>{data.full.count}</b></li>
            <li><span>Дата создания</span><i></i><b>{(new Date(data.full.created)).toLocaleString()}</b></li>
            <li><span>Пункт назначения</span><i></i><b>{data.full.dest == '1' && 'Гуанчжоу'}{data.full.dest == '2' && 'Алматы'}{data.full.dest == '3' && 'Москва'}</b></li> 
            <li><span>Адрес</span><i></i><b>{data.full.address}</b></li> 
          </ul>
        </div>
        <div className="wrap">
          <ul>
            <h3>Услуги по упаковке</h3>
            <li><span>Сортировка (цвет, размер)</span><i></i><b>{data.full.pack_1 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Маркировка «одинарная»</span><i></i><b>{data.full.pack_2 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Маркировка «двойная»</span><i></i><b>{data.full.pack_3 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Работа с бирками</span><i></i><b>{data.full.pack_4 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Проверка на брак визуальная</span><i></i><b>{data.full.pack_5 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Проверка на брак полная</span><i></i><b>{data.full.pack_6 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Формирование наборов</span><i></i><b>{data.full.pack_21 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Вложение промо в упаковку</span><i></i><b>{data.full.pack_10_1 && 'визитка'} {data.full.pack_10_2 && 'подарок'}{!data.full.pack_10_1 && !data.full.pack_10_1 && 'НЕТ'}</b></li> 
          </ul>
          <ul>
            <h3>Услуги по логистике</h3>
            <li><span>Доставка на склад до 1м3</span><i></i><b>{data.full.pack_11}</b></li>
            <li><span>Доставка на склад более 1м3</span><i></i><b>{data.full.pack_12}</b></li>
            <li><span>Паллет для отгрузки</span><i></i><b>{data.full.pack_13}</b></li>
            <li><span>Формирование паллетной отгрузки</span><i></i><b>{data.full.pack_14}</b></li>
          </ul>
          <ul>
            <h3>Дополнительные услуги</h3>
            <li><span>Забор груза с Карго</span><i></i><b>{data.full.pack_23 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Логистика до «FULFILLMENT-BQQ»</span><i></i><b>{data.full.pack_22 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Оформление поставки в ЛК</span><i></i><b>{data.full.pack_15 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Инфографика на фото</span><i></i><b>{data.full.pack_16 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Создание карточек товара</span><i></i><b>{data.full.pack_17 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Гос. регистрация и сертификация</span><i></i><b>{data.full.pack_18 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Регистрация ТЗ, защита ИС</span><i></i><b>{data.full.pack_19 ? 'ДА' : 'НЕТ'}</b></li>
            <li><span>Фотосессия</span><i></i><b>{data.full.pack_20 == 1 && 'модельная'}{data.full.pack_20 == 2 && 'предметная'}{data.full.pack_20 == 3 && 'стрит'}{data.full.pack_20 == 0 && 'НЕТ'}</b></li>
          </ul>


        </div>

        <div className="wrap">
          <ul>
            <h3>Упаковка</h3>
            <li><span className="caption">Пакет с липким краем</span><i></i><b>{data.full.pack_7_1 + data.full.pack_7_2 + data.full.pack_7_3}</b></li>
            {data.full.pack_7_1 && <li><span>до 20x30 см</span><i></i><b>{data.full.pack_7_1}</b></li>}
            {data.full.pack_7_2 && <li><span>25x30...30x40</span><i></i><b>{data.full.pack_7_2}</b></li>}
            {data.full.pack_7_3 && <li><span>35x40...50x70</span><i></i><b>{data.full.pack_7_3}</b></li>}
            <li><span className="caption">Zip-lock пакет с бегунком</span><i></i><b>{data.full.pack_8_1 + data.full.pack_8_2 + data.full.pack_8_3 + data.full.pack_8_4 + data.full.pack_8_5 + data.full.pack_8_6 + data.full.pack_8_7 + data.full.pack_8_8 + data.full.pack_8_9 + data.full.pack_8_10 + data.full.pack_8_11 + data.full.pack_8_12}</b></li>
            {data.full.pack_8_1 && <li><span>10x15 см</span><i></i><b>{data.full.pack_8_1}</b></li>}
            {data.full.pack_8_2 && <li><span>15x20 см</span><i></i><b>{data.full.pack_8_2}</b></li>}
            {data.full.pack_8_3 && <li><span>15x25 см</span><i></i><b>{data.full.pack_8_3}</b></li>}
            {data.full.pack_8_4 && <li><span>18x20 см</span><i></i><b>{data.full.pack_8_4}</b></li>}
            {data.full.pack_8_5 && <li><span>20x25 см</span><i></i><b>{data.full.pack_8_5}</b></li>}
            {data.full.pack_8_6 && <li><span>20x30 см</span><i></i><b>{data.full.pack_8_6}</b></li>}
            {data.full.pack_8_7 && <li><span>25x30 см</span><i></i><b>{data.full.pack_8_7}</b></li>}
            {data.full.pack_8_8 && <li><span>28x35 см</span><i></i><b>{data.full.pack_8_8}</b></li>}
            {data.full.pack_8_9 && <li><span>30x40 см</span><i></i><b>{data.full.pack_8_9}</b></li>}
            {data.full.pack_8_10 && <li><span>35x45 см</span><i></i><b>{data.full.pack_8_10}</b></li>}
            {data.full.pack_8_11 && <li><span>40x50 см</span><i></i><b>{data.full.pack_8_11}</b></li>}
            {data.full.pack_8_12 && <li><span>45x60 см</span><i></i><b>{data.full.pack_8_12}</b></li>}
            <li><span className="caption">Сейф-пакет</span><i></i><b>{data.full.pack_9_1 + data.full.pack_9_2 + data.full.pack_9_3 + data.full.pack_9_4 + data.full.pack_9_5 + data.full.pack_9_6 + data.full.pack_9_7}</b></li>
            {data.full.pack_9_1 && <li><span>30x40 см</span><i></i><b>{data.full.pack_9_1}</b></li>}
            {data.full.pack_9_2 && <li><span>36x50 см</span><i></i><b>{data.full.pack_9_2}</b></li>}
            {data.full.pack_9_3 && <li><span>30x40 см</span><i></i><b>{data.full.pack_9_3}</b></li>}
            {data.full.pack_9_4 && <li><span>16x32 см</span><i></i><b>{data.full.pack_9_4}</b></li>}
            {data.full.pack_9_5 && <li><span>50x50 см</span><i></i><b>{data.full.pack_9_5}</b></li>}
            {data.full.pack_9_6 && <li><span>25x40 см</span><i></i><b>{data.full.pack_9_6}</b></li>}
            {data.full.pack_9_7 && <li><span>10x15 см</span><i></i><b>{data.full.pack_9_7}</b></li>}
          </ul>  

        </div>

        <div className="wrap price-total">
          <ul>
            <li className="last"><span>ИТОГО</span><i></i><b>{new Intl.NumberFormat(format, {style: 'currency', currency}).format(data.full.total)}</b></li>
          </ul>
        </div>

        </div>

        </div>

    </div>
    )
  }
}
export default Full