import React, {useState, useEffect} from 'react'
import { easings } from 'react-animation'
import Media from 'react-media'

import OrderStatus from '../comps/OrderStatus.js'

function StoresReady({visible, focus, action, data}){
    const [selectedRow, setSelectedRow] = useState(focus),
          [isActive, setActive] = useState(false)

    useEffect(() => {
        if(focus != 0) setSelectedRow(focus)
    }, [focus])

    useEffect(() => {
        visible ? setActive(true) : setTimeout(() => setActive(false), 1000)
    }, [visible])

    if(isActive)
    return (
        <div id="delivery" style={visible ? { animation: `fade-in-up ${easings.easeOutExpo} 900ms forwards`} : { animation: `pop-out ${easings.easeOutExpo} 900ms forwards`}}>
            <div className="dtable">
              <div className="drow rhead">
                <div className="dhead">создан</div>
                <div className="dhead">клиент</div>
                <div className="dhead">номер</div>
                <div className="dhead">название</div>
                <div className="dhead">доставка</div>
                <div className="dhead">пункт</div>
                <div className="dhead">вес, г</div>	
                <div className="dhead">м3</div>
                <div className="dhead">шт</div>
                <div className="dhead dlast">статус</div>
              </div>

            {data.map(order => (
            <div key={order.id} className={selectedRow == order.track ? "drow drow-active" : "drow"} onClick={() => action(order.track)}>
              <div className="dcell">{String((new Date(order.created)).toLocaleTimeString()).slice(0,-3)} <span>{(new Date(order.created)).toLocaleDateString()}</span></div> 
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell"><span>Клиент:</span> {order.client}</div>
              ) : (
                <div className="dcell">{order.client}</div>
              )}
              </Media> 
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell">№ {order.track} ({order.dest})</div>
              ) : (
                <div className="dcell">{order.track}</div>
              )}
              </Media> 

              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell"><span>Груз:</span> {order.title.length > 30 ? order.title.slice(0,30) + ".." : order.title}</div>
              ) : (
                <div className="dcell">{order.title.length > 30 ? order.title.slice(0,30) + ".." : order.title}</div>
              )}
              </Media> 
              <Media queries={{small: '(max-width:800px)'}}>
              {(size) => size.small ? (
                <div className="dcell"><span>Доставка:</span> {order.type}</div>
              ) : (
                <div className="dcell">{order.type.length > 30 ? order.type.slice(0,30) + ".." : order.type}</div>
              )}
              </Media> 
              <div className="dcell">{order.dest}</div>	
              <div className="dcell">{order.weight}</div>
              <div className="dcell">{order.size}</div>
              <div className="dcell">{order.count}</div>
              <div className="dcell dlast align-center status"><OrderStatus status={order.status} /></div>
            </div> 
            ))}  
        </div>
      </div>
    )
}
export default StoresReady
