
import Bounce from 'react-reveal/Bounce'
import {ReactComponent as PlusSvg} from '../lib/svg/plus.svg'

function StoreButton({action}){
    return (
      <Bounce right opposite>
          <button className="grn" onClick={action}>новый груз <PlusSvg /></button>
      </Bounce>  
    )
}
export default StoreButton