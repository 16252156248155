import React, {useEffect, useState} from 'react'
import {useQuery, gql } from '@apollo/client'
import PropagateLoader from 'react-spinners/PropagateLoader'
import Fade from 'react-reveal/Fade'
import {ReactComponent as DensitySvg} from '../lib/svg/density.svg'
import {ReactComponent as RubSvg} from '../lib/svg/rub.svg'
import {ReactComponent as KztSvg} from '../lib/svg/kzt.svg'
import {ReactComponent as UsdSvg} from '../lib/svg/usd.svg'
import {ReactComponent as AwaitSvg} from '../lib/svg/await.svg'

const PRICE_URL = process.env.REACT_APP_PRICE_URL 

export default function OrderPriceCargo({rateCurrency, category, currency, weight, size, count, density, store, setTarifs}){
    const [cargoPrices, setCargoPrices] = useState([]),
          [priceStdCargo, setPriceStdCargo] = useState(0),
          [priceExpCargo, setPriceExpCargo] = useState(0),
          [priceOdnCargo, setPriceOdnCargo] = useState(0),
          [isLoad, setLoad] = useState(false),
          [dopPrice, setDopPrice] = useState([])


    const GET_CARGO = gql`query getCargos($category: Int) {cargos(category: $category){id price_1 price_2 price_3 category density}}`
    const {loading: loadingCargo, error: errorCargo, data: cargoList} = useQuery(GET_CARGO, {variables: {category: Number(category)}})  

    useEffect(() => {
      if(cargoList?.cargos && cargoList?.cargos.length > 0) {
        setCargoPrices([...cargoList.cargos])
        const priceRow = cargoList.cargos.length > 1 ? cargoList.cargos[category] : cargoList.cargos[0]
        setPriceStdCargo(priceRow.price_1)
        setPriceExpCargo(priceRow.price_2)
        setPriceOdnCargo(priceRow.price_3)
        setTarifs({std: priceRow.price_1, exp: priceRow.price_2, odn: priceRow.price_3})
      }
    }, [cargoList])

    useEffect(() => {
      (async () => {
          const file = 'cargo-dop'
          const result = await fetch(`${PRICE_URL}/${file}`)
          const data = await result.json()
          setDopPrice(data)
          setLoad(true)
      })()
    }, [])

    if(!weight || !size) return 
    if(!category) return 
    if(loadingCargo) return <p className="request-result"><PropagateLoader color="#392f64" /></p>
    if(errorCargo) return <p className="request-result">Ошибка: {errorCargo}</p>
    if(cargoPrices)
    return (
          <Fade bottom when={category}>
              <div className="price-wrap">
                  <div className="calc-result">
                      <ul>
                        <h3>Параметры груза</h3>
                        <li><span>Мест</span><i></i><b><small>шт</small> {count}</b></li>
                        <li><span>Масса</span><i></i><b><small>кг</small> {weight}</b></li>
                        <li><span>Объем</span><i></i><b><small>м<sup>3</sup></small> {size}</b></li>
                        <li><span>Плотность</span><i></i><b><small>кг/м<sup>3</sup></small> {density}</b></li>
                      </ul>

                      <ul>
                        <h3>Доступные маршруты</h3>
                        {/*<li><span>Курс <mark>USD/{currency}</mark> </span><i></i><b className="price">{currency == 'RUB' && <RubSvg />} {currency == 'KZT' && <KztSvg />} {rateCurrency}.00 </b></li>*/}
                        <li><span>Гуанчжоу <AwaitSvg /> Алматы | Гуанчжоу <AwaitSvg /> Москва</span><i></i></li>
                        <li className={priceExpCargo == 0 && 'disabled'}><span>ЭКСПРЕСС <mark>12..15 дней</mark></span><i></i><b className="price">{currency == 'RUB' && <RubSvg />} {currency == 'KZT' && <KztSvg />} {currency == 'USD' && <UsdSvg />} {new Intl.NumberFormat(currency, { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(weight * priceExpCargo * rateCurrency)}</b></li>
                        <li className={priceStdCargo == 0 && 'disabled'}><span>СТАНДАРТ <mark>18..30 дней</mark></span><i></i><b className="price">{currency == 'RUB' && <RubSvg />} {currency == 'KZT' && <KztSvg />} {currency == 'USD' && <UsdSvg />} {new Intl.NumberFormat(currency, { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(weight * priceStdCargo * rateCurrency)}</b></li>
                        <li className={priceOdnCargo == 0 && 'disabled'}><span>ОДНОТИП <mark>18..25 дней</mark></span><i></i><b className="price">{currency == 'RUB' && <RubSvg />} {currency == 'KZT' && <KztSvg />} {currency == 'USD' && <UsdSvg />} {new Intl.NumberFormat(currency, { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(weight * priceOdnCargo * rateCurrency)}</b></li>
                     </ul>

                      <ul>
                        <h3>Дополнительно</h3>
                        <li><span>Обрешётка</span><i></i><b className="price">{currency == 'RUB' && <RubSvg />} {currency == 'KZT' && <KztSvg />} {currency == 'USD' && <UsdSvg />} {new Intl.NumberFormat(currency, { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dopPrice.price_1 * rateCurrency * Math.ceil(size))}</b></li>
                        <li><span>Мешок закачивание</span><i></i><b className="price">{currency == 'RUB' && <RubSvg />} {currency == 'KZT' && <KztSvg />} {currency == 'USD' && <UsdSvg />} {new Intl.NumberFormat(currency, { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dopPrice.price_2 * rateCurrency * count)}</b></li>
                        <li><span>Пресс вакуум, шт</span><i></i><b className="price">{currency == 'RUB' && <RubSvg />} {currency == 'KZT' && <KztSvg />} {currency == 'USD' && <UsdSvg />} {new Intl.NumberFormat(currency, { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dopPrice.price_3 * rateCurrency)}</b></li>
                        <li><span>Паллет, шт</span><i></i><b className="price">{currency == 'RUB' && <RubSvg />} {currency == 'KZT' && <KztSvg />} {currency == 'USD' && <UsdSvg />} {new Intl.NumberFormat(currency, { minimumFractionDigits: 2, maximumFractionDigits: 2}).format(dopPrice.price_4 * rateCurrency)}</b></li>

                     </ul>
                  </div>

                 {store && <div className="dtable">
                    <div className="dhrow rhead">
                      <div className="dhead">Плотность груза</div>
                      <div className="dhead">Стандарт</div>
                      <div className="dhead">Экспресс</div>
                      <div className="dhead">Однотип</div>
                    </div>
                    {cargoPrices.map(row => (
                    <div key={row.id} className={(parseInt(density) >= row.density[0] && parseInt(density) < row.density[1]) ? "drow dactv" : "drow" }>
                      <div className="dcell"><DensitySvg /> {row.density[0]} ... {row.density[1]} <mark>кг/м<sup>3</sup></mark></div>
                      <div className="dcell">{row.price_1 > 0 ? <>{(row.price_1 * rateCurrency).toFixed(2)} <mark>{currency == 'RUB' && '\u20BD'} {currency == 'KZT' && '\u20B8'}/кг</mark></> : '-'}</div>
                      <div className="dcell">{row.price_2 > 0 ? <>{(row.price_2 * rateCurrency).toFixed(2)} <mark>{currency == 'RUB' && '\u20BD'} {currency == 'KZT' && '\u20B8'}/кг</mark></> : '-'}</div>
                      <div className="dcell">{row.price_3 > 0 ? <>{(row.price_3 * rateCurrency).toFixed(2)} <mark>{currency == 'RUB' && '\u20BD'} {currency == 'KZT' && '\u20B8'}/кг</mark></> : '-'}</div>
                    </div>
                    ))}
                  </div>}
                  <p className="note">В случае утери застрахованного груза возвращаем сумму груза полностью. Без страховки возвращаем <b>{2 * rateCurrency} {currency}</b> за 1 кг</p>
                  <p className="note">Стоимость указана только за услуги логистики без дополнительных расходов.</p>
              </div> 
        </Fade>
    )
}
