
import {ReactComponent as AwaitSvg} from '../lib/svg/await.svg'
import {ReactComponent as CheckSvg} from '../lib/svg/check.svg'
import {ReactComponent as CloseSvg} from '../lib/svg/close.svg'
import {ReactComponent as ArrowSvg} from '../lib/svg/arrow.svg'
import {ReactComponent as WarehouseInSvg} from '../lib/svg/warehouse-in.svg'
import {ReactComponent as WarehouseOutSvg} from '../lib/svg/warehouse-out.svg'
import {ReactComponent as AlmatySvg} from '../lib/svg/almaty.svg'
import {ReactComponent as MoscowSvg} from '../lib/svg/moscow.svg'

function OrderStatus({status}){
    if(status == 0) return <><WarehouseOutSvg /> <aside className="tooltip"><span>Не прибыл на склад</span><ArrowSvg /></aside></>
    if(status == 1) return <><WarehouseInSvg /> <aside className="tooltip"><span>Прибыл на склад</span><ArrowSvg /></aside></>
    if(status == 2) return <><AwaitSvg /> <aside className="tooltip"><span>Отправлен</span><ArrowSvg /></aside></>
    if(status == 3) return <><AlmatySvg /> <aside className="tooltip"><span>Отправлен в Алматы</span><ArrowSvg /></aside></>
    if(status == 4) return <><MoscowSvg /> <aside className="tooltip"><span>Отправлен в Москву</span><ArrowSvg /></aside></>
    if(status == 5) return <><CheckSvg /> <aside className="tooltip"><span>Выдан клиенту</span><ArrowSvg /></aside></>
}
export default OrderStatus