import React, {useRef, useState, useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import { useMutation, useQuery, gql } from '@apollo/client'
import { easings } from 'react-animation'
import PropagateLoader from 'react-spinners/PropagateLoader'
import {ReactComponent as CheckSvg} from '../lib/svg/check.svg'
import {ReactComponent as AwaitSvg} from '../lib/svg/await.svg'

import Uploader from '../comps/Uploader.js'

const PRICE_URL = process.env.REACT_APP_PRICE_URL


function FormFull({user, modal}){
    useEffect(() => {document.title = 'Заказ на фулфилмент :: BQQ Trade House'})
    const [currency, setCurrency] = useState('RUB'),
          [format, setFormat] = useState('ru-RU'),
          [client, setClient] = useState(0),
          [resident, setResident] = useState(0),
          [comment, setComment] = useState(null),
          [count, setCount] = useState(0),
          [title, setTitle] = useState(''),
          [track, setTrack] = useState(''),
          [dest, setDest] = useState(1),
          [address, setAddress] = useState(''),
          [pack_price, setPrice] = useState({}),
          [total, setTotal] = useState(0),
          [image, setImage] = useState([]),
          [isFull, setFull] = useState(false),
          [pack_1, setPack_1] = useState(false),
          [pack_2, setPack_2] = useState(false),
          [pack_3, setPack_3] = useState(false),
          [pack_4, setPack_4] = useState(false),
          [pack_5, setPack_5] = useState(false),
          [pack_6, setPack_6] = useState(false),
          [pack_7, setPack_7] = useState(false),
          [pack_7_1, setPack_7_1] = useState(0),
          [pack_7_2, setPack_7_2] = useState(0),
          [pack_7_3, setPack_7_3] = useState(0),
          [pack_8, setPack_8] = useState(false),
          [pack_8_1, setPack_8_1] = useState(0),
          [pack_8_2, setPack_8_2] = useState(0),
          [pack_8_3, setPack_8_3] = useState(0),
          [pack_8_4, setPack_8_4] = useState(0),
          [pack_8_5, setPack_8_5] = useState(0),
          [pack_8_6, setPack_8_6] = useState(0),
          [pack_8_7, setPack_8_7] = useState(0),
          [pack_8_8, setPack_8_8] = useState(0),
          [pack_8_9, setPack_8_9] = useState(0),
          [pack_8_10, setPack_8_10] = useState(0),
          [pack_8_11, setPack_8_11] = useState(0),
          [pack_8_12, setPack_8_12] = useState(0),
          [pack_9, setPack_9] = useState(false),
          [pack_9_1, setPack_9_1] = useState(0),
          [pack_9_2, setPack_9_2] = useState(0),
          [pack_9_3, setPack_9_3] = useState(0),
          [pack_9_4, setPack_9_4] = useState(0),
          [pack_9_5, setPack_9_5] = useState(0),
          [pack_9_6, setPack_9_6] = useState(0),
          [pack_9_7, setPack_9_7] = useState(0),
          [pack_10, setPack_10] = useState(false),
          [pack_10_1, setPack_10_1] = useState(false),
          [pack_10_2, setPack_10_2] = useState(false),
          [pack_11, setPack_11] = useState(null),
          [pack_12, setPack_12] = useState(null),
          [pack_13, setPack_13] = useState(null),
          [pack_14, setPack_14] = useState(null),
          [pack_15, setPack_15] = useState(false),
          [pack_16, setPack_16] = useState(false),
          [pack_17, setPack_17] = useState(false),
          [pack_18, setPack_18] = useState(false),
          [pack_19, setPack_19] = useState(false),
          [pack_20, setPack_20] = useState(0),
          [pack_20_1, setPack_20_1] = useState(false),
          [pack_20_2, setPack_20_2] = useState(false),
          [pack_20_3, setPack_20_3] = useState(false),
          [pack_21, setPack_21] = useState(false),
          [pack_22, setPack_22] = useState(false),
          [pack_23, setPack_23] = useState(false),
          ref_pack_1 = useRef(),
          ref_pack_2 = useRef(),
          ref_pack_3 = useRef(),
          ref_pack_4 = useRef(),
          ref_pack_5 = useRef(),
          ref_pack_6 = useRef(),
          ref_pack_7 = useRef(),
          ref_pack_8 = useRef(),
          ref_pack_9 = useRef(),
          ref_pack_10 = useRef(),
          ref_pack_11 = useRef(),
          ref_pack_12 = useRef(),
          ref_pack_13 = useRef(),
          ref_pack_14 = useRef(),
          ref_pack_15 = useRef(),
          ref_pack_16 = useRef(),
          ref_pack_17 = useRef(),
          ref_pack_18 = useRef(),
          ref_pack_19 = useRef(),
          ref_pack_20 = useRef(),
          ref_pack_21 = useRef(),
          ref_pack_23 = useRef()
          
    const returnURL = (url) => {
          setImage(url)
    }

    const GET_CLIENTS = gql`query getClients{clients{fio client resident}}`
    const {data: clientsList} = useQuery(GET_CLIENTS)

    const GET_ORDERS = gql`query getOrders {orders{id track}}`
    const {data} = useQuery(GET_ORDERS)

    const NEW_FULL = gql`
    mutation newFull($user: ID, $client: Int, $comment: String, $title: String, $track: String, $total: Float, $count: Int, $dest: Int, $address: String, $pack_1: Boolean, $pack_2: Boolean, $pack_3: Boolean, $pack_4: Boolean, $pack_5: Boolean, $pack_6: Boolean, $pack_7: Boolean, $pack_7_1: Int, $pack_7_2: Int, $pack_7_3: Int, $pack_8: Boolean, $pack_8_1: Int, $pack_8_2: Int, $pack_8_3: Int, $pack_8_4: Int, $pack_8_5: Int, $pack_8_6: Int, $pack_8_7: Int, $pack_8_8: Int, $pack_8_9: Int, $pack_8_10: Int, $pack_8_11: Int, $pack_8_12: Int, $pack_9: Boolean, $pack_9_1: Int, $pack_9_2: Int, $pack_9_3: Int, $pack_9_4: Int, $pack_9_5: Int, $pack_9_6: Int, $pack_9_7: Int, $pack_10: Boolean, $pack_10_1: Boolean, $pack_10_2: Boolean, $pack_11: Int, $pack_12: Int, $pack_13: Int, $pack_14: Int, $pack_15: Boolean, $pack_16: Boolean, $pack_17: Boolean, $pack_18: Boolean, $pack_19: Boolean, $pack_20: Int, $pack_21: Boolean, $pack_22: Boolean, $pack_23: Int, $image: [String]) {
      newFull(user: $user, client: $client, comment: $comment, title: $title, track: $track, total: $total, count: $count, dest: $dest, address: $address, pack_1: $pack_1, pack_2: $pack_2, pack_3: $pack_3, pack_4: $pack_4, pack_5: $pack_5, pack_6: $pack_6, pack_7: $pack_7, pack_7_1: $pack_7_1, pack_7_2: $pack_7_2, pack_7_3: $pack_7_3, pack_8: $pack_8, pack_8_1: $pack_8_1, pack_8_2: $pack_8_2, pack_8_3: $pack_8_3, pack_8_4: $pack_8_4, pack_8_5: $pack_8_5, pack_8_6: $pack_8_6, pack_8_7: $pack_8_7, pack_8_8: $pack_8_8, pack_8_9: $pack_8_9, pack_8_10: $pack_8_10, pack_8_11: $pack_8_11, pack_8_12: $pack_8_12, pack_9: $pack_9, pack_9_1: $pack_9_1, pack_9_2: $pack_9_2, pack_9_3: $pack_9_3, pack_9_4: $pack_9_4, pack_9_5: $pack_9_5, pack_9_6: $pack_9_6, pack_9_7: $pack_9_7, pack_10: $pack_10, pack_10_1: $pack_10_1, pack_10_2: $pack_10_2, pack_11: $pack_11, pack_12: $pack_12, pack_13: $pack_13, pack_14: $pack_14, pack_15: $pack_15, pack_16: $pack_16, pack_17: $pack_17, pack_18: $pack_18, pack_19: $pack_19, pack_20: $pack_20, pack_21: $pack_21, pack_22: $pack_22, pack_23: $pack_23, image: $image)}` 

    const letFull = (e) => {
      e.preventDefault()
      newFull({variables: {user: user.id, client: Number(client), title, comment, track, total: Number(total), count: Number(count), dest: Number(dest), address, pack_1, pack_2, pack_3, pack_4, pack_5, pack_6, pack_7, pack_7_1: Number(pack_7_1), pack_7_2: Number(pack_7_2), pack_7_3: Number(pack_7_3), pack_8, pack_8_1: Number(pack_8_1), pack_8_2: Number(pack_8_2), pack_8_3: Number(pack_8_3), pack_8_4: Number(pack_8_4), pack_8_5: Number(pack_8_5), pack_8_6: Number(pack_8_6), pack_8_7: Number(pack_8_7), pack_8_8: Number(pack_8_8), pack_8_9: Number(pack_8_9), pack_8_10: Number(pack_8_10), pack_8_11: Number(pack_8_11), pack_8_12: Number(pack_8_12), pack_9, pack_9_1: Number(pack_9_1), pack_9_2: Number(pack_9_2), pack_9_3: Number(pack_9_3), pack_9_4: Number(pack_9_4), pack_9_5: Number(pack_9_5), pack_9_6: Number(pack_9_6), pack_9_7: Number(pack_9_7), pack_10, pack_10_1, pack_10_2, pack_11: Number(pack_11), pack_12: Number(pack_12), pack_13: Number(pack_13), pack_14: Number(pack_14), pack_15, pack_16, pack_17, pack_18, pack_19, pack_20, pack_21, pack_22, pack_23: Number(pack_23), image}})
    } 

    const [newFull, { loading, error }] = useMutation(NEW_FULL, {
        onCompleted: data => {
            setFull(true)
            setTimeout(() => setFull(false), 2000)
            modal('full')
        }
    }) 

    useEffect(() => {
      console.log(image)
    }, [image])

    useEffect(() => {
      (async () => {
          setCurrency(resident == 1 ? 'RUB' : 'KZT')
          setFormat(resident == 1 ? 'ru-RU' : 'kz-KZ')
          const file = resident == 1 ? 'ff-rub' : 'ff-kzt'
          const result = await fetch(`${PRICE_URL}/${file}`)
          const data = await result.json()
          setPrice(data)
      })()
    }, [resident])
    
    useEffect(() => {
        setTotal(
          parseInt(ref_pack_1?.current?.innerText) + 
          parseInt(ref_pack_2?.current?.innerText) +
          parseInt(ref_pack_3?.current?.innerText) +
          parseInt(ref_pack_4?.current?.innerText) +
          parseInt(ref_pack_5?.current?.innerText) +
          parseInt(ref_pack_6?.current?.innerText) +
          parseInt(ref_pack_7?.current?.innerText) +
          parseInt(ref_pack_8?.current?.innerText) +
          parseInt(ref_pack_9?.current?.innerText) +
          parseInt(ref_pack_10?.current?.innerText) +
          parseInt(ref_pack_11?.current?.innerText) +
          parseInt(ref_pack_12?.current?.innerText) +
          parseInt(ref_pack_13?.current?.innerText) +
          parseInt(ref_pack_14?.current?.innerText) +
          parseInt(ref_pack_15?.current?.innerText) +
          parseInt(ref_pack_16?.current?.innerText) +
          parseInt(ref_pack_17?.current?.innerText) +
          parseInt(ref_pack_18?.current?.innerText) +
          parseInt(ref_pack_19?.current?.innerText) +
          parseInt(ref_pack_20?.current?.innerText) +
          parseInt(ref_pack_21?.current?.innerText) + 
          parseInt(ref_pack_23?.current?.innerText)
        )
    })

    if(loading) return <p className="request-result"><PropagateLoader color="#392f64" /></p>
    if(error) return <p className="request-result">Ошибка: {error}</p>
    if(isFull) return <Navigate to="/full" />
    return (
        <div id="fullfilment-form" style={{ animation: `pop-in ${easings.easeInOutQuart} 900ms forwards`}}>
          <div className="top-comp-wrap">
            <div className="head"><h3>Заказ на Фулфилмент</h3></div>
            <form className="mt-0" method="post" onSubmit={letFull}>
              <fieldset className={title == "" ? "field-wrap mb-2" : "field-wrap mb-2 ready"}>
                <input type="text" id="title" name="title" onChange={(e) => setTitle(e.target.value)} required />
                <label htmlFor="title">Наименование товара <CheckSvg /></label>
              </fieldset>
              <fieldset className={track == "" ? "field-wrap mb-2" : "field-wrap mb-2 ready"}>
                <input type="text" list="order-list" id="track" name="track" onChange={(e) => setTrack(e.target.value)} autoComplete='off' required />
                <label htmlFor="track">Трэк номер <CheckSvg /></label>
                <datalist id="order-list">
                  {data?.orders.map(order => <option value={order.track} />)}
                </datalist>
              </fieldset>
              <fieldset className={count > 0 ? "field-wrap mb-2 ready" : "field-wrap mb-2"}>
                <input type="number" id="count" name="count" autoComplete="off" onChange={(e) => setCount(e.target.value)} required />
                <label htmlFor="count">Количество <CheckSvg /></label>
              </fieldset>
              <fieldset className={client == "" ? "field-wrap mb-2" : "field-wrap mb-2 ready"}>
                <input type="text" list="client-list" id="client" name="client" 
                        onChange={(e) => {
                          setClient(e.target.value.split(" | ")[0]) 
                          setResident(e.target.value.split(" | ")[1])
                        }} autoComplete='off' required />
                <label htmlFor="client">Клиент <CheckSvg /></label>
                <datalist id="client-list">
                  {clientsList && clientsList.clients.map(client => <option value={`${client.client} | ${client.resident}`} label={client.fio} />)}
                </datalist>
              </fieldset>
              <fieldset className={comment == null ? "field-wrap field-wrap-2 mb-2" : "field-wrap field-wrap-2 mb-2 ready"}>
                <input type="text" id="comment" name="comment" value={comment} onChange={(e) => setComment(e.target.value)} />
                <label htmlFor="title">Комментарий <CheckSvg /></label>
              </fieldset>


              <fieldset className={count > 0 ? "fieldset-wrap wrap-first active" : "fieldset-wrap wrap-first"}>
                <fieldset className="box-wrap">
                  <label><b>Услуги по упаковке</b></label>
                  <aside className="first"><input type="checkbox" id="pack_1" name="pack" onChange={() => setPack_1(v => !v)} /> <label htmlFor="pack_1">Сортировка (цвет, размер)<mark ref={ref_pack_1} className={pack_1 && 'active'}>{pack_1 && (pack_price['pack_1'] * count) || 0}</mark></label></aside>
                  <aside><input type="checkbox" id="pack_2" name="pack_2" onChange={() => setPack_2(v => !v)} /> <label htmlFor="pack_2">Маркировка для маркетплейса «одинарная»<mark ref={ref_pack_2} className={pack_2 && 'active'}>{pack_2 && (pack_price['pack_2'] * count) || 0}</mark></label></aside>
                  <aside><input type="checkbox" id="pack_3" name="pack_3" onChange={() => setPack_3(v => !v)} /> <label htmlFor="pack_3">Маркировка для маркетплейса «двойная»<mark ref={ref_pack_3} className={pack_3 && 'active'}>{pack_3 && (pack_price['pack_3'] * count) || 0}</mark></label></aside>
                  <aside><input type="checkbox" id="pack_4" name="pack_4" onChange={() => setPack_4(v => !v)} /> <label htmlFor="pack_4">Работа с бирками (навес, пристрел, снятие)<mark ref={ref_pack_4} className={pack_4 && 'active'}>{pack_4 && (pack_price['pack_4'] * count) || 0}</mark></label></aside>
                  <aside><input type="checkbox" id="pack_5" name="pack_5" onChange={() => setPack_5(v => !v)} /> <label htmlFor="pack_5">Проверка на брак (визуальный осмотр)<mark ref={ref_pack_5} className={pack_5 && 'active'}>{pack_5 && (pack_price['pack_5'] * count) || 0}</mark></label></aside>
                  <aside><input type="checkbox" id="pack_6" name="pack_6" onChange={() => setPack_6(v => !v)} /> <label htmlFor="pack_6">Проверка на брак (полный осмотр)<mark ref={ref_pack_6} className={pack_6 && 'active'}>{pack_6 && (pack_price['pack_6'] * count) || 0}</mark></label></aside>
                  <aside className="last"><input type="checkbox" id="pack_21" name="pack_21" onChange={() => setPack_21(v => !v)} /> <label htmlFor="pack_21">Формирование наборов<mark ref={ref_pack_21} className={pack_21 && 'active'}>{pack_21 && (pack_price['pack_21'] * count) || 0}</mark></label></aside>
                </fieldset>
                <fieldset className="box-wrap">
                  <label><b>Услуги по логистике на склад Маркетплейса</b></label>
                  <aside className="first last box-inline">
                    <span><input type="number" id="pack_11" name="pack_11" onChange={(e) => setPack_11(e.target.value)} /><label htmlFor="pack_11">Доставка на склад до 1м3 (до 10 коробок 60х40х40 см)<mark ref={ref_pack_11} className={pack_11 > 0 && 'active'}>{pack_price['pack_11'] * pack_11}</mark></label></span>
                    <span><input type="number" id="pack_12" name="pack_12" onChange={(e) => setPack_12(e.target.value)} /><label htmlFor="pack_12">Доставка на склад более 1м3 (до 20 коробок 60х40х40 см)<mark ref={ref_pack_12} className={pack_12 > 0 && 'active'}>{pack_price['pack_12'] * pack_12}</mark></label></span>
                    <span><input type="number" id="pack_13" name="pack_13" onChange={(e) => setPack_13(e.target.value)} /><label htmlFor="pack_13">Паллет для отгрузки (1 шт)<mark ref={ref_pack_13} className={pack_13 > 0 && 'active'}>{pack_price['pack_13'] * pack_13}</mark></label></span>
                    <span><input type="number" id="pack_14" name="pack_14" onChange={(e) => setPack_14(e.target.value)} /><label htmlFor="pack_14">Формирование паллетной отгрузки (1 шт.)<mark ref={ref_pack_14} className={pack_14 > 0 && 'active'}>{pack_price['pack_14'] * pack_14}</mark></label></span>
                  </aside>
                </fieldset>
                <fieldset className="box-wrap">
                  <label><b>Дополнительные услуги для наших клиентов</b></label>
                  <aside className="first flex-wrap"><span><input type="checkbox" id="pack_22" name="pack_22" onChange={() => setPack_22(v => !v)} /> <label htmlFor="pack_22">Логистика от поставщика до «FULFILLMENT-BQQ»</label></span>
                    {pack_22 && <input type="text" placeholder="укажите адрес доставки" id="address" name="address" onChange={(e) => setAddress(e.target.value)} />}
                  </aside>
                  <aside><input type="checkbox" id="pack_23" name="pack_23" checked={pack_23} onChange={() => setPack_23(v => !v)} /> <label htmlFor="pack_23">Забор груза с Карго<mark ref={ref_pack_23} className={pack_23 && 'active'}>{pack_23 && pack_price['pack_23'] * (parseInt(pack_11) + parseInt(pack_12)) || 0}</mark></label></aside>
                  <aside><input type="checkbox" id="pack_15" name="pack_15" onChange={() => setPack_15(v => !v)} /> <label htmlFor="pack_15">Оформление поставки в личном кабинете<mark ref={ref_pack_15} className={pack_15 && 'active'}>{pack_15 && pack_price['pack_15'] || 0}</mark></label></aside>
                  <aside><input type="checkbox" id="pack_16" name="pack_16" onChange={() => setPack_16(v => !v)} /> <label htmlFor="pack_16">Инфографика на фото<mark ref={ref_pack_16} className={pack_16 && 'active'}>{pack_16 && pack_price['pack_16'] || 0}</mark></label></aside>
                  <aside><input type="checkbox" id="pack_17" name="pack_17" onChange={() => setPack_17(v => !v)} /> <label htmlFor="pack_17">Создание карточек товара<mark ref={ref_pack_17} className={pack_17 && 'active'}>{pack_17 && pack_price['pack_17'] || 0}</mark></label></aside>
                  <aside><input type="checkbox" id="pack_18" name="pack_18" onChange={() => setPack_18(v => !v)} /> <label htmlFor="pack_18">Государственная регистрация и сертификация<mark ref={ref_pack_18} className={pack_18 && 'active'}>{pack_18 && pack_price['pack_18'] || 0}</mark></label></aside>
                  <aside className="last"><input type="checkbox" id="pack_19" name="pack_19" onChange={() => setPack_19(v => !v)} /> <label htmlFor="pack_19">Регистрация ТЗ, защита интеллектуальной собств.<mark ref={ref_pack_19} className={pack_19 && 'active'}>{pack_19 && pack_price['pack_19'] || 0}</mark></label></aside>
                </fieldset>
                <fieldset className="box-wrap">
                  <label><b>Фотосессия</b><mark ref={ref_pack_20} className={pack_20 && 'active'}>{pack_20 && pack_price['pack_20'] || 0}</mark></label>
                  <aside className="first last box-inline span-inline">
                    <span><input type="radio" id="pack_20_1" name="pack_20" onChange={() => setPack_20(1)} /> <label htmlFor="pack_20_1">модельная</label></span>
                    <span><input type="radio" id="pack_20_3" name="pack_20" onChange={() => setPack_20(3)} /> <label htmlFor="pack_20_3">предметная</label></span>
                    <span><input type="radio" id="pack_20_2" name="pack_20" onChange={() => setPack_20(2)} /> <label htmlFor="pack_20_2">стрит</label></span>
                    <span><input type="radio" id="pack_20_0" name="pack_20" onChange={() => setPack_20(0)} /> <label htmlFor="pack_20_0">нет</label></span>
                  </aside>
                </fieldset>
                {count > 0 && <Uploader returnURL={returnURL} />}
                <aside className="total">Итого: <span>{new Intl.NumberFormat(format, {style: 'currency', currency}).format(total)}</span></aside>
              </fieldset>
              <fieldset className={count > 0 ? "fieldset-wrap wrap-last active" : "fieldset-wrap wrap-last"}>
                <fieldset className="box-wrap">
                  <label><b>Упаковка</b><mark></mark></label>
                  <aside className="first"><input type="checkbox" id="pack_7" name="pack_7" checked={pack_7} onChange={() => setPack_7(v => !v)} /> <label htmlFor="pack_7">пакет с липким краем<mark ref={ref_pack_7} className={pack_7 && 'active'}>{pack_7_1 * pack_price['pack_7_1'] + pack_7_2 * pack_price['pack_7_2'] + pack_7_3 * pack_price['pack_7_3']}</mark></label></aside>
                  <aside className={pack_7 ? "box-inline" : "box-inline disabled"}>
                    <span><label htmlFor="pack_7_1">до 20x30 см</label><br /><input type="number" id="pack_7_1" name="pack_7_1" onChange={(e) => setPack_7_1(e.target.value)} disabled={!pack_7} /></span>
                    <span><label htmlFor="pack_7_2">25x30...30x40</label><br /><input type="number" id="pack_7_2" name="pack_7_2" onChange={(e) => setPack_7_2(e.target.value)} disabled={!pack_7} /></span>
                    <span><label htmlFor="pack_7_3">35x40...50x70</label><br /><input type="number" id="pack_7_3" name="pack_7_3" onChange={(e) => setPack_7_3(e.target.value)} disabled={!pack_7} /></span>
                  </aside>
                  <aside><input type="checkbox" id="pack_8" name="pack_8" checked={pack_8} onChange={() => setPack_8(v => !v)} /> <label htmlFor="pack_8">zip-lock пакет с бегунком<mark ref={ref_pack_8} className={pack_8 && 'active'}>{pack_8_1 * pack_price['pack_8_1'] + pack_8_2 * pack_price['pack_8_2'] + pack_8_3 * pack_price['pack_8_3'] + pack_8_4 * pack_price['pack_8_4'] + pack_8_5 * pack_price['pack_8_5'] + pack_8_6 * pack_price['pack_8_6'] + pack_8_7 * pack_price['pack_8_7'] + pack_8_8 * pack_price['pack_8_8'] + pack_8_9 * pack_price['pack_8_9'] + pack_8_10 * pack_price['pack_8_10'] + pack_8_11 * pack_price['pack_8_11'] + pack_8_12 * pack_price['pack_8_12']}</mark></label></aside>
                  <aside className={pack_8 ? "box-inline" : "box-inline disabled"}>
                    <span><label htmlFor="pack_8_1">10x15 см</label><br /><input type="number" id="pack_8_1" name="pack_8_1" onChange={(e) => setPack_8_1(e.target.value)} disabled={!pack_8} /></span>
                    <span><label htmlFor="pack_8_2">15x20 см</label><br /><input type="number" id="pack_8_2" name="pack_8_2" onChange={(e) => setPack_8_2(e.target.value)} disabled={!pack_8} /></span>
                    <span><label htmlFor="pack_8_3">15x25 см</label><br /><input type="number" id="pack_8_3" name="pack_8_3" onChange={(e) => setPack_8_3(e.target.value)} disabled={!pack_8} /></span>
                    <span><label htmlFor="pack_8_4">18x20 см</label><br /><input type="number" id="pack_8_4" name="pack_8_4" onChange={(e) => setPack_8_4(e.target.value)} disabled={!pack_8} /></span>
                    <span><label htmlFor="pack_8_5">20x25 см</label><br /><input type="number" id="pack_8_5" name="pack_8_5" onChange={(e) => setPack_8_5(e.target.value)} disabled={!pack_8} /></span>
                    <span><label htmlFor="pack_8_6">20x30 см</label><br /><input type="number" id="pack_8_6" name="pack_8_6" onChange={(e) => setPack_8_6(e.target.value)} disabled={!pack_8} /></span>
                    <span><label htmlFor="pack_8_7">25x30 см</label><br /><input type="number" id="pack_8_7" name="pack_8_7" onChange={(e) => setPack_8_7(e.target.value)} disabled={!pack_8} /></span>
                    <span><label htmlFor="pack_8_8">28x35 см</label><br /><input type="number" id="pack_8_8" name="pack_8_8" onChange={(e) => setPack_8_8(e.target.value)} disabled={!pack_8} /></span>
                    <span><label htmlFor="pack_8_9">30x40 см</label><br /><input type="number" id="pack_8_9" name="pack_8_9" onChange={(e) => setPack_8_9(e.target.value)} disabled={!pack_8} /></span>
                    <span><label htmlFor="pack_8_10">35x45 см</label><br /><input type="number" id="pack_8_10" name="pack_8_10" onChange={(e) => setPack_8_10(e.target.value)} disabled={!pack_8} /></span>
                    <span><label htmlFor="pack_8_11">40x50 см</label><br /><input type="number" id="pack_8_11" name="pack_8_11" onChange={(e) => setPack_8_11(e.target.value)} disabled={!pack_8} /></span>
                    <span><label htmlFor="pack_8_12">45x60 см</label><br /><input type="number" id="pack_8_12" name="pack_8_12" onChange={(e) => setPack_8_12(e.target.value)} disabled={!pack_8} /></span>
                  </aside>
                  <aside><input type="checkbox" id="pack_9" name="pack_9" checked={pack_9} onChange={() => setPack_9(v => !v)} /> <label htmlFor="pack_9">сейф-пакет<mark ref={ref_pack_9} className={pack_9 && 'active'}>{pack_9_1 * pack_price['pack_9_1'] + pack_9_2 * pack_price['pack_9_2'] + pack_9_3 * pack_price['pack_9_3'] + pack_9_4 * pack_price['pack_9_4'] + pack_9_5 * pack_price['pack_9_5'] + pack_9_6 * pack_price['pack_9_6'] + pack_9_7 * pack_price['pack_9_7']}</mark></label></aside>
                  <aside className={pack_9 ? "last box-inline" : "last box-inline disabled"}>
                    <span><label htmlFor="pack_9_1">30x40 см</label><br /><input type="number" id="pack_9_1" name="pack_9_1" onChange={(e) => setPack_9_1(e.target.value)} disabled={!pack_9} /></span>
                    <span><label htmlFor="pack_9_2">36x50 см</label><br /><input type="number" id="pack_9_2" name="pack_9_2" onChange={(e) => setPack_9_2(e.target.value)} disabled={!pack_9} /></span>
                    <span><label htmlFor="pack_9_3">30x40 см</label><br /><input type="number" id="pack_9_3" name="pack_9_3" onChange={(e) => setPack_9_3(e.target.value)} disabled={!pack_9} /></span>
                    <span><label htmlFor="pack_9_4">16x32 см</label><br /><input type="number" id="pack_9_4" name="pack_9_4" onChange={(e) => setPack_9_4(e.target.value)} disabled={!pack_9} /></span>
                    <span><label htmlFor="pack_9_5">50x50 см</label><br /><input type="number" id="pack_9_5" name="pack_9_5" onChange={(e) => setPack_9_5(e.target.value)} disabled={!pack_9} /></span>
                    <span><label htmlFor="pack_9_6">25x40 см</label><br /><input type="number" id="pack_9_6" name="pack_9_6" onChange={(e) => setPack_9_6(e.target.value)} disabled={!pack_9} /></span>
                    <span><label htmlFor="pack_9_7">10x15 см</label><br /><input type="number" id="pack_9_7" name="pack_9_7" onChange={(e) => setPack_9_7(e.target.value)} disabled={!pack_9} /></span>
                  </aside>
                </fieldset>  
                <fieldset className="box-wrap">
                  <label><b>Вложение промо в упаковку</b><mark ref={ref_pack_10} className={(pack_10_1 || pack_10_2) && 'active'}>{(pack_10_1 || pack_10_2) && (pack_price['pack_10'] * count) || 0}</mark></label>
                  <aside className="first last box-inline">
                    <span><input type="checkbox" id="pack_10_1" name="pack_10" onChange={() => setPack_10_1(v => !v)} /> <label htmlFor="pack_10_1">визитка</label></span>
                    <span><input type="checkbox" id="pack_10_2" name="pack_10" onChange={() => setPack_10_2(v => !v)} /> <label htmlFor="pack_10_2">подарок</label></span>
                  </aside>
                </fieldset>
                <fieldset className="radio-wrap bg-green">
                  <legend>Город фулфилмента</legend>
                  <div className="button-box-wrap"><table><tr><th><label htmlFor="cy">Гуанчжоу</label></th><td><input type="radio" name="dest" id="cy" checked={dest == 1 && "true"} onChange={() => setDest(1)} /></td></tr></table></div>
                  <div className="button-box-wrap"><table><tr><th><label htmlFor="kz">Алматы</label></th><td><input type="radio" name="dest" id="kz" checked={dest == 2 && "true"} onChange={() => setDest(2)} /></td></tr></table></div>
                  <div className="button-box-wrap"><table><tr><th><label htmlFor="ru">Москва</label></th><td><input type="radio" name="dest" id="ru" checked={dest == 3 && "true"} onChange={() => setDest(3)} /></td></tr></table></div>
                </fieldset>
                <button className="grn" type="submit">отправить <AwaitSvg /></button>
              </fieldset>
            </form>
          </div>
        </div>
    )
}
export default FormFull