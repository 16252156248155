
import Bounce from 'react-reveal/Bounce'
import {ReactComponent as LoginSvg} from '../lib/svg/login.svg'

function Login(props){
    return (
      <Bounce right opposite>
          <form method="post">
            <button type="submit" onClick={props.action}>вход <LoginSvg /></button>
          </form>
      </Bounce>  
    )
}
export default Login