
import React, {useRef} from 'react'
import Flip from 'react-reveal/Flip'
import { useQuery, gql } from '@apollo/client'
import {ReactComponent as LoupeSvg} from '../lib/svg/loupe.svg'
import PulseLoader from 'react-spinners/PulseLoader'

function StoreSearch({group, action}){
    let status = Number(group)

    const searchRef = useRef()
    const onSearch = (e) => {
      e.preventDefault()
      searchRef.current.value != '' && action(searchRef.current.value)
    }

    const GET_ORDERS = gql`
    query getStores($status: Int) {
      stores(status: $status){
        id
        track
      }
    }`

    const {loading, error, data} = useQuery(GET_ORDERS, { variables: { status }})
    if(loading) return <PulseLoader color="#392f64" size={10} /> 
    return (
      <Flip top opposite>
          <form method="post" onSubmit={(e) => onSearch(e)}>
              <input type="search" list="search-list" ref={searchRef} placeholder="..введите номер заказа" /> <LoupeSvg onClick={(e) => onSearch(e)} />
              <datalist id="search-list">
                {data?.stores?.map(order => <option value={order.track} />)}
              </datalist>
          </form>
      </Flip>  
    )

}
export default StoreSearch