
import Bounce from 'react-reveal/Bounce'
import {ReactComponent as PlusSvg} from '../lib/svg/plus.svg'

function Address(props){
    return (
      <Bounce right opposite>
          <button className="red adrs" onClick={props.action}>новый заказ <PlusSvg /></button>
      </Bounce>  
    )
}
export default Address