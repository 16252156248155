
import {ReactComponent as AwaitSvg} from '../lib/svg/await.svg'
import {ReactComponent as CheckSvg} from '../lib/svg/check.svg'
import {ReactComponent as CloseSvg} from '../lib/svg/close.svg'
import {ReactComponent as ArrowSvg} from '../lib/svg/arrow.svg'
import {ReactComponent as WarehouseInSvg} from '../lib/svg/warehouse-in.svg'
import {ReactComponent as WarehouseOutSvg} from '../lib/svg/warehouse-out.svg'
import {ReactComponent as AlmatySvg} from '../lib/svg/almaty.svg'
import {ReactComponent as MpSvg} from '../lib/svg/mp.svg'

import {ReactComponent as NewSvg} from '../lib/svg/new.svg'
import {ReactComponent as CallSvg} from '../lib/svg/call.svg'
import {ReactComponent as PaySvg} from '../lib/svg/pay.svg'
import {ReactComponent as PackSvg} from '../lib/svg/pack.svg'

function FullStatus({status}){
    if(status == 0) return <><NewSvg /> <aside className="tooltip"><span>Новая заявка</span><ArrowSvg /></aside></>
    if(status == 1) return <><CallSvg /> <aside className="tooltip"><span>Связались</span><ArrowSvg /></aside></>
    if(status == 2) return <><PaySvg /> <aside className="tooltip"><span>Оплачено</span><ArrowSvg /></aside></>
    if(status == 3) return <><PackSvg /> <aside className="tooltip"><span>Идет сборка</span><ArrowSvg /></aside></>
    if(status == 4) return <><WarehouseInSvg /> <aside className="tooltip"><span>Готово к отправке</span><ArrowSvg /></aside></>
    if(status == 5) return <><AwaitSvg /> <aside className="tooltip"><span>Отправлено</span><ArrowSvg /></aside></>
    if(status == 6) return <><MpSvg /> <aside className="tooltip"><span>Принято на МП</span><ArrowSvg /></aside></>
    if(status == 7) return <><CheckSvg /> <aside className="tooltip"><span>Завершено</span><ArrowSvg /></aside></>

}
export default FullStatus