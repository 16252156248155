import React, {useState, useEffect} from 'react'
import { useMutation, gql } from '@apollo/client'
import { easings } from 'react-animation'

import {ReactComponent as CheckSvg} from '../lib/svg/check.svg'
import {ReactComponent as SmsSvg} from '../lib/svg/sms.svg'

function Confirm({mobile, action}){
    const [time, setTime] = useState(60),
          [send, setSend] = useState(true),
          [code, setCode] = useState(null),
          [subm, setSubm] = useState(true),
          [resp, setResp] = useState('')

    const newTime = () =>{
          setTime(60)
          setSend(true)    
          sendSms({variables: {mobile}}) 
    } 
    
    useEffect(() => {
        let newTime
        if(time < 1) setSend(false)
        else{
          newTime = setInterval(() => setTime(v => v - 1), 1000) 
          return () => clearInterval(newTime)
        }  
    }, [time])

    useEffect(() => {
        sendSms({variables: {mobile}}) 
    }, [])    
    

    const SEND_SMS = gql`
    mutation sendSms($mobile: String){sendSms(mobile: $mobile)}` 
      
    const [sendSms, { }] = useMutation(SEND_SMS, {
        onCompleted: data => {
          if(data.sendSms === 0) setResp('На указанный номер отправлено сообщение с кодом подтверждения')
          else setResp('Ошибка отправки сообщения') 
        },
        onError: error => {
          setResp(error) 
        }
    }) 

    const SUBM_SMS = gql`
    mutation submSms($code: Int){submSms(code: $code)}` 
      
    const [submSms, { }] = useMutation(SUBM_SMS, {
        onCompleted: data => {
          if(data.submSms) {
            setResp('Номер подтвержден')
            setSend(true)
            setSubm(true)
            action()
          }
          else{
            setResp('Вы ввели неправильный код') 
            setSend(false)
            setSubm(false)
          }
        },
        onError: error => {
          setResp(error) 
        }
    }) 

    const onChange = (e) => {
      let code = e.target.value
      if(code.length < 6) setCode(code)
      if(code.length > 4) setSubm(false)
      else setSubm(true)
    }  

    return (
        <div className="half-comp-wrap" style={{ animation: `slide-in ${easings.easeOutExpo} 800ms forwards`}}>
            <div className="head"><h3>Подтвердить номер</h3></div>
            <div className="options">
                  <h3 className="align-center">{resp}</h3><br />
                  <p>На РФ номера SMS-сообщения временно не доходят. Попробуйте подтвердить номер через 10 дней.</p>
            </div>
            <form onSubmit={(e) => {
                e.preventDefault()
                submSms({variables: {code: Number(code)}})}
              }>
              <fieldset className="field-wrap">
                <input type="text" id="phone" name="phone" value={mobile} readOnly />
                <label htmlFor="phone">Тел. Мобильный </label>
              </fieldset>
              <fieldset className="field-wrap">
                <input type="number" id="sms" name="sms" value={code} onChange={onChange} />
                <label htmlFor="sms">Код из СМС</label>
              </fieldset>
              <button className="yel sms" onClick={newTime} disabled={send}><span>отправить снова</span> {time > 0 ? <b>{time}</b> : <SmsSvg />}</button><button type="submit" className="grn" disabled={subm}>подтвердить <CheckSvg /></button>
            </form>
        </div>
    )
}
export default Confirm