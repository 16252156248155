import React, {lazy, Suspense, useState, useEffect} from 'react'
import {NavLink, Link, Routes, Route, Navigate, useNavigate} from 'react-router-dom'
import Media from 'react-media'
import SquareLoader from 'react-spinners/SquareLoader'

import './lib/css/root.css'
import './lib/css/head.css'
import './lib/css/form.css'
import './lib/css/style.css'

import {ReactComponent as CloseSvg} from './lib/svg/close.svg'

import LogoPng from './lib/img/logo.png'

import User from './comps/User.js'
import Store from './comps/Store.js'
import Full from './comps/Full.js'
import StoreFull from './comps/StoreFull.js'
import LoginButton from './comps/LoginButton.js'
import OrderButton from './comps/OrderButton.js'
import StoreButton from './comps/StoreButton.js'
import AddressButton from './comps/AddressButton.js'
import FormAuth from './comps/FormAuth.js'
import FormOrder from './comps/FormOrder.js'
import FormFull from './comps/FormFull.js'
import FormStore from './comps/FormStore.js'
import FormStoreFull from './comps/FormStoreFull.js'
import OrderSearch from './comps/OrderSearch.js'
import StoreSearch from './comps/StoreSearch.js'
import OrderStat from './comps/OrderStat.js'
import StoreStat from './comps/StoreStat.js'
import Time from './comps/Time.js'
import Rate from './comps/Rate.js'
import WhatsApp from './comps/WhatsApp.js'

import Home from './section/Home.js'
import Address from './section/Address.js'
import Profile from './section/Profile.js'
import Installer from "./Installer"


const Reset = lazy(() => import('./section/Reset.js')),
      Delivery = lazy(() => import('./section/Delivery.js')),
      Stores = lazy(() => import('./section/Stores.js')),
      Fulls = lazy(() => import('./section/Fulls.js')),
      StoreFulls = lazy(() => import('./section/StoreFulls.js')),
      Contact = lazy(() => import('./section/Contact.js')),
      Register = lazy(() => import('./section/Register.js')),
      Chart = lazy(() => import('./section/Chart.js')),
      Users = lazy(() => import('./section/Users.js')),
      Calculator = lazy(() => import('./section/Calculator.js')),
      FullPrice = lazy(() => import('./section/FullPrice.js')),
      CargoPrice = lazy(() => import('./section/CargoPrice.js'))
    //  CargoPriceNew = lazy(() => import('./section/CargoPriceNew.js'))

const CHECK_URL = process.env.REACT_APP_CHECK_URL

function App() {
  const loadPage = useNavigate(),
        [USER, setUSER] = useState({
            id: localStorage.getItem('bqq-id') || null,
            login: localStorage.getItem('bqq-login') || null,
            token: localStorage.getItem('bqq-token') || null,
            client: localStorage.getItem('bqq-client') || null,
            group: localStorage.getItem('bqq-group') || null,
            resident: localStorage.getItem('bqq-resident') || null,
            address: localStorage.getItem('bqq-address') == 'true' ? true : false}),
        [EXIT, setEXIT] = useState(false),
        [isModal, setModal] = useState(false),
        [isLogged, setLogged] = useState(USER.token != null ? true : false),
        [isAuth, setAuth] = useState(false),
        [isOrder, setOrder] = useState(false),
        [isFull, setFull] = useState(false),
        [isVisible, setVisible] = useState(false),
        [orderID, setOrderID] = useState(0),
        [fullID, setFullID] = useState(0),
        [userID, setUserID] = useState(0),
        [isPulse, setPulse] = useState(false),
        [storeRefetch, setStoreRefetch] = useState(false),
        [deliveryRefetch, setDeliveryRefetch] = useState(false),
        [fullRefetch, setFullRefetch] = useState(false),
        [navActive, setNavActive] = useState(false),
        [rateExport, setRateExport] = useState([])

  function onLogin(id, login, client, resident, group, token, address){
    address = address == 'true' ? true : false
    localStorage.setItem('bqq-id', id)
    localStorage.setItem('bqq-login', login)
    localStorage.setItem('bqq-client', client)
    localStorage.setItem('bqq-resident', resident)
    localStorage.setItem('bqq-group', group)
    localStorage.setItem('bqq-token', token)
    localStorage.setItem('bqq-address', address)
    setUSER({id, login, client, resident, group, token, address})
    setAuth(false)
    setLogged(true)
    setVisible(false)
    setModal(false)
  }
  function addressConfirm(){
    localStorage.setItem('bqq-address', true)
    setUSER((oldState) => ({...oldState, address: Boolean(true)}))
  } 
  function onLogout(){
    localStorage.clear()
    setNavActive(false)
    setLogged(false)
    setEXIT(true)
  }
  function showUser(user){
    setUserID(user)
    setModal(true)
    setNavActive(false)
  }
  function showOrder(order){
    setUserID(0)
    setOrderID(order)
    setModal(true)
    setNavActive(false)
  }
  function showFull(full){
    setUserID(0)
    setFullID(full)
    setModal(true)
    setNavActive(false)
  }
  function hideModal(page = null){
    if(page) {
      if(page =='full') setFullRefetch(true) 
      getPage(page)
    }
    setNavActive(false)
    setOrderID(0) 
    setFullID(0)
    setUserID(0) 
    setAuth(false)
    setOrder(false)
    setFull(false)
    setVisible(false)
    setModal(false)
    setDeliveryRefetch(true)
    setStoreRefetch(true)
  }
  function delOrder(){
    hideModal()
    setStoreRefetch(true)
  }
  function delFull(){
    hideModal()
    setFullRefetch(true)
  }
  function getPage(page){
    setAuth(false)
    setVisible(false)
    setModal(false)
    loadPage(`${page}`)
    setNavActive(false)
  }  
  function letAuth(e){
    e.preventDefault()
    setNavActive(false)
    if(isVisible){
        setTimeout(() => {
          setAuth(false)
          setModal(false)
        }, 1000)
    }
    setVisible(v => !v)
    setModal(true)
    setAuth(true)
  }


  function letOrder(){
    setNavActive(false)
    if(isVisible){
        setTimeout(() => {
          setOrder(false)
          setModal(false)
          setVisible(false)
        }, 1000)
    }
    else{
      setVisible(true)
      setModal(true)
      setOrder(true)
    }
  }
  function letFull(){
    setNavActive(false)
    if(isVisible){
        setTimeout(() => {
          setFull(false)
          setModal(false)
          setVisible(false)
        }, 1000)
    }
    else{
      setVisible(true)
      setModal(true)
      setFull(true)
    }
  }

  function letAddress(){
    setNavActive(false)
    loadPage('/profile')
    setPulse(true)
    setTimeout(() => setPulse(false), 2000)
  }

  useEffect(() => {
      setTimeout(() => {
        if(EXIT){
          setEXIT(false)
          loadPage('/')
        }
      }, 600)
  }, [EXIT])

  useEffect(() => {
    (async () => {
      let name  = 'token',
          value = USER.token,
          result, data
      if(!value) return
      result = await fetch(`${CHECK_URL}/${name}/${value}`)
      data   = await result.json()
      if(data.check == 0) onLogout()
    })()   
  }, [])

  return (
    <div className={isModal ? "App modal-active" : "App"} >
    <Installer>
    <Media queries={{small: '(max-width:800px)'}}>
    {(size) => size.small ? (
      <>
      <header className={isLogged && "active"}>
        <div className="bg">
          <div id="logo" onClick={() => getPage('/')}>
            <img src={LogoPng} />
          </div>
          <div className={navActive ? "toggler toggler-open" : "toggler"} onClick={() => setNavActive(!navActive)}><span></span><span></span><span></span></div>
        </div>
      </header>
      <nav className={!navActive ? "close" : null}>

        {isLogged && <NavLink to="/profile" activeClassName="active" onClick={() => setNavActive(!navActive)}>профиль</NavLink>}

        {isLogged && <NavLink to="/address" activeClassName="active" onClick={() => setNavActive(!navActive)}>адрес склада</NavLink>}

        {isLogged && USER.group == 5 && <NavLink to="/delivery" activeClassName="active" onClick={() => setNavActive(!navActive)}>ваши грузы</NavLink>}

        {isLogged && (USER.group == 1 || USER.group == 2 || USER.group == 3 || USER.group == 4) && <NavLink to="/store" activeClassName="active" onClick={() => setNavActive(!navActive)}>склад</NavLink>}

        {!isLogged && <NavLink to="/register" activeClassName="active" onClick={() => hideModal(orderID)}>регистрация</NavLink>}

        {isLogged && USER.group == 4 && <NavLink to="/full/store" activeClassName="active" onClick={() => setNavActive(!navActive)}>фулфилмент</NavLink>}

        {isLogged && USER.group == 5 && <NavLink to="/full" activeClassName="active" onClick={() => setNavActive(!navActive)}>фулфилмент</NavLink>}

        <NavLink to="/calculator" activeClassName="active" onClick={() => setNavActive(!navActive)}>калькулятор</NavLink>

        {isLogged && USER.group == 4 && <NavLink to="/users" activeClassName="active" onClick={() => setNavActive(!navActive)}>пользователи</NavLink>}

        {USER.group != 4 && <NavLink to="/contact" activeClassName="active" onClick={() => setNavActive(!navActive)}>обратная связь</NavLink>}

        <div id="hello">
            {isLogged ? <h4>Здравствуйте,<br />{USER.login || "Посетитель"}! <span onClick={onLogout}><u>выход</u></span></h4> : <h4 className="anon">Здравствуйте, Гость!</h4>}
        </div>
        <div id="login">
          {isLogged && USER.group == 5 && <OrderStat client={USER.client} />}
          {isLogged && (USER.group == 1 || USER.group == 2 || USER.group == 3 || USER.group == 4) && <StoreStat group={USER.group} />}
        </div>

        <div id="search">
          {!isLogged && (
            <table>
            <tr>
              <td>Для заказа доставки грузов <span onClick={() => getPage('/register')}>зарегистрируйтесь</span> или <span onClick={letAuth}>войдите</span></td>
            </tr>
            </table>
          )}
          {isLogged && USER.group == 5 && <OrderSearch client={USER.client} action={showOrder} />}
          {isLogged && (USER.group == 1 || USER.group == 2 || USER.group == 3 || USER.group == 4) && <StoreSearch group={USER.group} action={showOrder} />}
        </div>

        <div id="rate"><Rate group={USER.group} rateExport={setRateExport} /></div>
        <div id="info"><Time /></div>

        <div id="user">
            {isLogged ? (
                USER.group == 5 ? (
                    USER.address ? <OrderButton actionOrder={letOrder} actionFull={letFull} /> : <AddressButton action={letAddress} />
                  ) : (
                    <StoreButton action={letOrder}  />
                  )
              ) : <LoginButton action={letAuth} />}
        </div>
      </nav>
      </>
    ) : (
      <>
      <header className={isLogged && "active"}>
        <div id="logo" onClick={() => getPage('/')}>
          <img src={LogoPng} />
        </div>

        <div id="hello">
            {isLogged ? <h4><b>Здравствуйте,<br /><u>{USER.login || "Посетитель"}</u></b> <span onClick={onLogout}><u>выход</u></span></h4> : <h4 className="anon">Здравствуйте, Гость!</h4>}
        </div>
        <div id="login">
          {isLogged && USER.group == 5 && <OrderStat client={USER.client} />}
          {isLogged && (USER.group == 1 || USER.group == 2 || USER.group == 3 || USER.group == 4) && <StoreStat group={USER.group} />}
        </div>

        <div id="search">
          {!isLogged && (
            <table>
            <tr>
              <td>Для заказа доставки грузов <span onClick={() => getPage('/register')}>зарегистрируйтесь</span> или <span onClick={letAuth}>войдите</span></td>
            </tr>
            </table>
          )}
          {isLogged && USER.group == 5 && <OrderSearch client={USER.client} action={showOrder} />}
          {isLogged && (USER.group == 1 || USER.group == 2 || USER.group == 3 || USER.group == 4) && <StoreSearch group={USER.group} action={showOrder} />}
        </div>

        <div id="user">
            {isLogged ? (
                USER.group == 5 ? (
                    USER.address ? <OrderButton actionOrder={letOrder} actionFull={letFull} /> : <AddressButton action={letAddress} />
                  ) : (
                    <StoreButton action={letOrder} />
                  )
              ) : <LoginButton action={letAuth} />}
        </div>

        <div id="rate"><Rate group={USER.group} rateExport={setRateExport} /></div>
        <div id="info"><Time /></div>
      </header>
      <nav>
        {isLogged && <NavLink to="/profile" activeClassName="active">профиль</NavLink>}

        {isLogged && <NavLink to="/address" activeClassName="active" onClick={() => setNavActive(!navActive)}>адрес склада</NavLink>}

        {isLogged && USER.group == 5 && <NavLink to="/delivery" activeClassName="active">ваши грузы</NavLink>}

        {isLogged && (USER.group == 1 || USER.group == 2 || USER.group == 3 || USER.group == 4) && <NavLink to="/store" activeClassName="active">склад</NavLink>}

        {!isLogged && <NavLink to="/register" activeClassName="active" onClick={() => hideModal(orderID)}>регистрация</NavLink>}

        {isLogged && USER.group == 4 && <NavLink to="/full/store" activeClassName="active">фулфилмент</NavLink>}

        {isLogged && USER.group == 5 && <NavLink to="/full" activeClassName="active">фулфилмент</NavLink>}

        <NavLink to="/calculator" activeClassName="active" onClick={() => setNavActive(!navActive)}>калькулятор</NavLink>

        {isLogged && USER.group == 4 && <NavLink to="/users" activeClassName="active">пользователи</NavLink>}

        {isLogged && USER.group == 4 && <NavLink to="/chart" activeClassName="active">статистика</NavLink>}

        {USER.group != 4 && <NavLink to="/contact" activeClassName="active">контакт</NavLink>}
      </nav>
      </>
    )}
    </Media> 
      <section id="content">
      <WhatsApp user={USER} />
        <article className={isModal ? "main" : "main active"}>
        <Routes>
            <Route path="/reset/:reset" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><Reset action={onLogin} /></Suspense>} />
            <Route path="/reset" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><Reset /></Suspense>} />
            <Route path="/users" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><Users user={USER} focus={userID} action={showUser} /></Suspense>} />
            <Route path="/chart" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><Chart group={USER.group} /></Suspense>} />
            <Route path="/store" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><Stores group={USER.group} focus={orderID} refetch={storeRefetch} action={showOrder} actionOrder={letOrder} actionFull={letFull} getPage={getPage} /></Suspense>} />
            {/*<Route path="/delivery/cargo/price/new" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><CargoPriceNew user={USER} /></Suspense>} />*/}
            <Route path="/delivery/cargo/price" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><CargoPrice user={USER} /></Suspense>} />
            <Route path="/delivery" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><Delivery user={USER} focus={orderID} refetch={deliveryRefetch} action={showOrder} actionOrder={letOrder} actionFull={letFull} actionAddress={letAddress} /></Suspense>} />
            <Route path="/register" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><Register auth={isLogged} action={onLogin} /></Suspense>} />
            <Route path="/full/store" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><StoreFulls user={USER} focus={fullID} refetch={fullRefetch} action={showFull} actionOrder={letOrder} actionFull={letFull} getPage={getPage} /></Suspense>} />
            <Route path="/full" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><Fulls user={USER} focus={fullID} refetch={fullRefetch} action={showFull} actionOrder={letOrder} actionFull={letFull} actionAddress={letAddress} /></Suspense>} />
            <Route path="/full/price" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><FullPrice user={USER} /></Suspense>} />
            <Route path="/profile" element={<Profile user={USER} focusFull={fullID} focusOrder={orderID} action={onLogin} showOrder={showOrder} showFull={showFull} logout={onLogout} address={addressConfirm} pulse={isPulse} />} />
            <Route path="/address" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><Address user={USER} logout={onLogout} address={addressConfirm} pulse={isPulse} /></Suspense>} />
            <Route path="/contact" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><Contact user={USER} /></Suspense>} />
            <Route path="/calculator" element={<Suspense fallback={<p className="request-result"><SquareLoader color="#392f64" /></p>}><Calculator rateExport={rateExport} /></Suspense>} />
            <Route path="/" element={isLogged ? <Navigate replace to={"/delivery"} /> : <Home action={onLogin} />} />
        </Routes> 
        </article>
      </section>

        {isAuth && (<div className="modal modal-active">
          <div className="close" onClick={() => hideModal()}><CloseSvg /></div>
          <div className="displ">{isAuth && <FormAuth visible={isVisible} action={onLogin} />}</div>
        </div>)}

        {isOrder && (<div className="modal modal-active">
          <div className="close" onClick={() => hideModal()}><CloseSvg /></div>
          <div className="displ">
           {USER.group == 5 && isOrder && <FormOrder user={USER} modal={hideModal} visible={true} rateExport={rateExport} />}
           {(USER.group == 1 || USER.group == 2 || USER.group == 3 || USER.group == 4) && isOrder && <FormStore user={USER} modal={hideModal} rateExport={rateExport} />}
          </div>
        </div>)}

        <div className={orderID != "" ? "modal modal-active" : "modal"}>
          <div className="close" onClick={() => hideModal()}><CloseSvg /></div>
          <div className="displ">
            {USER.group == 5 && orderID && <FormOrder user={USER} modal={hideModal} visible={isVisible} rateExport={rateExport} orderTrack={orderID} />}
            {(USER.group == 1 || USER.group == 2 || USER.group == 3 || USER.group == 4) && orderID && <Store user={USER} action={delOrder} rateExport={rateExport} orderTrack={orderID} />}
          </div>
        </div>

        {isFull && (<div className="modal modal-active">
          <div className="close" onClick={() => hideModal()}><CloseSvg /></div>
          <div className="displ">
           {(USER.group == 1 || USER.group == 2 || USER.group == 3 || USER.group == 4) && isModal && <FormStoreFull user={USER} modal={hideModal} />}
           {USER.group == 5 && isModal && <FormFull user={USER} modal={hideModal} />}
          </div>
        </div>)}        

        {fullID != "" && (<div className="modal modal-active">
          <div className="close" onClick={() => hideModal()}><CloseSvg /></div>
          <div className="displ">
            {USER.group == 5 && fullID && <Full track={fullID} />}
            {(USER.group == 1 || USER.group == 2 || USER.group == 3 || USER.group == 4) && fullID && <StoreFull full={fullID} user={USER} action={delFull} />}
          </div>
        </div>)}

        {userID != "" && (<div className="modal modal-active">
          <div className="close" onClick={() => hideModal()}><CloseSvg /></div>
          <div className="displ">
            {USER.group == 4 && userID && <User user={userID} actionOrder={showOrder} actionFull={showFull} />}
          </div>
        </div>)}

      <footer><div className="flex"><aside className="others">Политика Конфиденциальности</aside> <aside className="copyright">2024 © Все права защищены</aside></div></footer>
    </Installer>
    </div>
  )
}

export default App
