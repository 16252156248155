import React, {useRef, useState, useEffect} from 'react'
import { useMutation, gql } from '@apollo/client'
import {Link} from 'react-router-dom'
import InputMask from 'react-input-mask'
import Fade from 'react-reveal/Fade'

import {ReactComponent as LoginSvg} from '../lib/svg/login.svg'

function PhoneInput(props) {
  return (
    <InputMask 
      mask='+9(999)999-99-99'
      id={props.id}
      type={props.type} 
      name={props.name} 
      value={props.value} 
      onChange={props.onChange}>
    </InputMask>
  )
}

function FormHome(props){
    const mobileRef = useRef(),
          [result, setResult] = useState(''),
          [password, setPassword] = useState(''),
          [mobile, setMobile] = useState('')

    const onChange = (event) => {
      if(event.target.name == 'mobile') setMobile(event.target.value) 
      else setPassword(event.target.value)
    }

    const SIGNIN_USER = gql`
    mutation signIn($mobile: String, $password: String) {
      signIn(mobile: $mobile, password: $password)
    }`  

    const [signIn, { loading, error }] = useMutation(SIGNIN_USER, {
        onCompleted: data => {
            console.log(data)
            if     (data.signIn == "user") setResult('Пользователь не найден')
            else if(data.signIn == "pass") setResult('Неверный логин или пароль')
            else{
              const user = data.signIn.split("|")
              props.action(user[0], user[1], user[2], user[3], user[4], user[5], user[6])
            }  
        }
    }) 
 

    return (
      <Fade left opposite>
        <div id="form-home">
            <div className="head">
              <h3>Авторизация <span>{result}</span></h3>
            </div>
            <form method="post" onSubmit={(e) => {
                   e.preventDefault()
                   signIn({variables: {mobile, password}})
                }
              }>
              <fieldset className={mobile ? "field-wrap ready" : "field-wrap"}>
                <PhoneInput type="tel" className="mobile" id="mobile-home" name="mobile" required value={mobile} onChange={onChange} autoFocus />
                <label htmlFor="mobile-home">Тел. Мобильный</label>
              </fieldset>
              <fieldset className={password ? "field-wrap ready" : "field-wrap"}>
                <input type="password" id="password-home" name="password" required ref={mobileRef} value={password} onChange={onChange} />
                <label htmlFor="password-home">Пароль</label>
              </fieldset>
              <div className="flex"><Link to="/reset">напомнить пароль</Link><button className="grn" type="submit">вход <LoginSvg /></button></div>
            </form>
        </div>
      </Fade>
    )
}
export default FormHome