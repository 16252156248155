
import React, {useState} from 'react'
import Fade from 'react-reveal/Fade'
import {ReactComponent as PlusSvg} from '../lib/svg/fulfillment.svg'

function FullButton({action}){
    const [timer, setTimer] = useState(false)
    setTimeout(() => setTimer(true), 500)
    return (
      <Fade bottom when={timer}>
          <button className="grn full" onClick={action}>фулфилмент <PlusSvg /></button>
      </Fade> 
    )
}
export default FullButton