import React, {useEffect, useState} from 'react'
import {useQuery, gql } from '@apollo/client'
import SquareLoader from 'react-spinners/SquareLoader'
import Flip from 'react-reveal/Flip'

export default function OrderPriceCategory({trackReady, trackCheck, category, setCategory, setCategoryTitle, editable=false}){
    const reduceCategory = (cat, title) => {
      if(trackReady && !editable) return
      if(cat == category){
        setCategory(0) 
        setCategoryTitle('')
      }
      else{
        setTimeout(() => { 
          setCategory(cat)
          setCategoryTitle(title)
        }, 1000)
      }
    }
    
    const GET_CARGO_CATEGORY = gql`query getCargoCategory{cargoCategory{value title image}}`
    const {loading, error, data} = useQuery(GET_CARGO_CATEGORY)

    useEffect(() => {
      if(data) {
      //  setCategory() 
      //  setCategoryTitle()
      }
    }, [data])

    if(loading) return <p className="request-result"><SquareLoader color="#392f64" /></p>
    if(error) return <p className="request-result">Ошибка: {error}</p>
    if(data)
    return (
        <fieldset className={category > 0 ? "category-wrap category-ready" : "category-wrap"}>
        <Flip left cascade delay={1000}>
          <ul className={trackReady && 'disabled'}>
            {data.cargoCategory.map(cat => <li key={cat.value} className={cat.value == category && 'active'} onClick={() => reduceCategory(cat.value, cat.title)}><img src={cat.image} alt={cat.title} /><br />{cat.title}</li>)}
          </ul>
        </Flip>
        </fieldset> 
        
    )
}
